<div class="content">
  <div class="row mb-4">
    <div class="cards invite-card me-3">
      <div class="cards-header">
        <h3 class="cards-heading">Edit Sub Categories</h3>
      </div>
      <div class="invite-content">
        <form
          class="d-flex mob-flex p-4"
          [formGroup]="generalForm"
          (ngSubmit)="updateSubCategory()"
        >
          <div class="input-group mb-2 mt-4">
            <input
              type="text"
              class="form-control input-area"
              placeholder="Sub Categories"
              aria-label="invite"
              aria-describedby="basic-addon2"
              formControlName="categories"
            />
          </div>
          <button type="submit" class="btn invite-btn ms-2 mt-4">Update</button>
        </form>
      </div>
    </div>
  </div>
</div>
