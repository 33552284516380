import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { TermsConditionComponent } from "./terms-condition/terms-condition.component";
import { UsersComponent } from "./users/users.component";
import { LegalDocumentsComponent } from "./legal-documents/legal-documents.component";
import { HelpRequestComponent } from "./help-request/help-request.component";
import { PaymentComponent } from "./payment/payment.component";
import { PromotionComponent } from "./promotion/promotion.component";
import { NewsletterComponent } from "./newsletter/newsletter.component";
import { TicketComponent } from "./ticket/ticket.component";
import { CreateNewComponent } from "./create-new/create-new.component";
import { GuestNewsletterComponent } from "./guest-newsletter/guest-newsletter.component";
import { ProfileComponent } from "./profile/profile.component";
import { BusinessNewsletterComponent } from "./business-newsletter/business-newsletter.component";
import { BusinessSettingComponent } from "./business-setting/business-setting.component";
import { ViewUserComponent } from "./view-user/view-user.component";
import { EditUserComponent } from "./edit-user/edit-user.component";
import { ViewPaymentComponent } from "./view-payment/view-payment.component";
import { EditNewsletterComponent } from "./edit-newsletter/edit-newsletter.component";
import { EditBusinessnewsletterComponent } from "./edit-businessnewsletter/edit-businessnewsletter.component";
import { ViewBusinessUserComponent } from "./view-business-user/view-business-user.component";
import { EditBusinessUserComponent } from "./edit-business-user/edit-business-user.component";
import { DrinkSettingComponent } from "./drink-setting/drink-setting.component";
import { EditManagerComponent } from "./edit-manager/edit-manager.component";
import { ViewManagerComponent } from "./view-manager/view-manager.component";
import { ViewEditorsComponent } from "./view-editors/view-editors.component";
import { EditEditorsComponent } from "./edit-editors/edit-editors.component";
import { VerifyUsersComponent } from "./verify-users/verify-users.component";
import { ViewVerifyUserComponent } from "./view-verify-user/view-verify-user.component";
import { ViewVerifyBusinessComponent } from "./view-verify-business/view-verify-business.component";
import { VerifyDocumentsComponent } from "./verify-documents/verify-documents.component";
import { ViewVerifyDocumentComponent } from "./view-verify-document/view-verify-document.component";
import { EditCategoriesComponent } from "./edit-categories/edit-categories.component";
import { EditSubcategoriesComponent } from "./edit-subcategories/edit-subcategories.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { EmailBodyComponent } from "./email-body/email-body.component";
import { PagenotfoundComponent } from "../auth/pagenotfound/pagenotfound.component";

const routes: Routes = [
  { path: "dashboard", component: DashboardComponent },
  { path: "terms-condition", component: TermsConditionComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "users", component: UsersComponent },
  { path: "legal-documents", component: LegalDocumentsComponent },
  { path: "help-center", component: HelpRequestComponent },
  { path: "payment", component: PaymentComponent },
  { path: "promotion", component: PromotionComponent },
  { path: "Newsletter", component: NewsletterComponent },
  { path: "help-center/ticket/:support_id", component: TicketComponent },
  { path: "create-new", component: CreateNewComponent },
  { path: "guest-newsletter", component: GuestNewsletterComponent },
  { path: "legal-documents/profile", component: ProfileComponent },
  { path: "business-newsletter", component: BusinessNewsletterComponent },
  { path: "business-setting", component: BusinessSettingComponent },
  { path: "view-user/:id", component: ViewUserComponent },
  { path: "edit-user/:id", component: EditUserComponent },
  { path: "view-payment/:id", component: ViewPaymentComponent },
  { path: "edit-newsletter/:id", component: EditNewsletterComponent },
  { path: "view-business-user/:id", component: ViewBusinessUserComponent },
  { path: "edit-business-user/:id", component: EditBusinessUserComponent },
  { path: "drink-setting", component: DrinkSettingComponent },
  {
    path: "edit-businessnewsletter/:id",
    component: EditBusinessnewsletterComponent,
  },
  {
    path: "edit-manager/:id",
    component: EditManagerComponent,
  },
  {
    path: "view-manager/:id",
    component: ViewManagerComponent,
  },
  {
    path: "edit-editors/:id",
    component: EditEditorsComponent,
  },
  {
    path: "view-editors/:id",
    component: ViewEditorsComponent,
  },
  { path: "verify-users", component: VerifyUsersComponent },
  {
    path: "view-verify-user/:id",
    component: ViewVerifyUserComponent,
  },
  {
    path: "view-verify-business/:id",
    component: ViewVerifyBusinessComponent,
  },
  { path: "verify-docments", component: VerifyDocumentsComponent },
  {
    path: "view-verify-document/:id",
    component: ViewVerifyDocumentComponent,
  },
  {
    path: "edit-category/:id",
    component: EditCategoriesComponent,
  },
  {
    path: "edit-subcategory/:id",
    component: EditSubcategoriesComponent,
  },
  {
    path: "change-password",
    component: ChangePasswordComponent,
  },
  {
    path: "email-body",
    component: EmailBodyComponent,
  },{
      path:'**',
      pathMatch: 'full',  
      component:PagenotfoundComponent
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
