import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
// import { Editor } from "ngx-editor";
@Component({
  selector: "app-email-body",
  templateUrl: "./email-body.component.html",
  styleUrls: ["./email-body.component.css"],
})
export class EmailBodyComponent implements OnInit {
  // editor: Editor | undefined;
  // html = "";

  ngOnInit(): void {
    // this.editor = new Editor();
  }

  constructor(private httpClient: HttpClient) {}
}
