import { Component, ChangeDetectorRef, AfterViewInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { PageServiceService } from "./pages/page-service.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "AdminDrinkApp";
  subscribe: Subscription = new Subscription();
  loading: any = false;
  user: any = localStorage.getItem("user")
    ? localStorage.getItem("user")
    : null;
  constructor(
    public pageService: PageServiceService,
    private cdr: ChangeDetectorRef,
  ) {
    this.pageService.user.subscribe((x: any) => {
      if (x) {
        this.user = Object.keys(x).length > 0 ? x : null;
      } else {
        this.user = null;
      }
    });
  }
  ngAfterViewInit() {
    this.subscribe = this.pageService.loading.subscribe((res: any) => {
      this.loading = res;
      this.cdr.detectChanges();
      
    });
  }
  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  
}
