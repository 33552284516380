import { Component, OnInit,inject  } from "@angular/core";
import { PageServiceService } from "../page-service.service";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
// import * as pdfMake from 'pdfmake/build/pdfmake';
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
// Add fonts to pdfMake
// (pdfMake as any).vfs = {
//   'Roboto-Regular.ttf': 'BASE64_ENCODED_STRING_HERE'  // Replace with actual Base64
// };
import { NgbCalendar, NgbDate, NgbDateParserFormatter,NgbModalRef,NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';

declare var require: any;
const pdfMake = require('pdfmake/build/pdfmake');
const pdfFonts = require('pdfmake/build/vfs_fonts');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.css"],
  providers:[ 
    DatePipe,
  ]
})
export class PaymentComponent implements OnInit {
  originalGuestPayment: any = [];
  businessPayment: any = [];
  totalItems: any;
  currentPage = 1;
  errorMessage: string = "";
  userText: any = "";
  viewFilter:boolean=false;
  filterForm!: FormGroup;
  private searchTextSubject = new Subject<string>();
  selectedDateRangeForDownload:any;
  hoveredDate: NgbDate | null = null;
  
	fromDate: NgbDate | null = null;
	toDate: NgbDate | null = null ;
  
  private modalRef: NgbModalRef | undefined;
  constructor(
    public pageService: PageServiceService,
    private http: HttpClient,
    private config: NgbPaginationConfig,
    private fb:FormBuilder,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    public calendar: NgbCalendar, 
    public formatter: NgbDateParserFormatter
  ) {
    config.maxSize = 20;
    config.pageSize = 10;
  }

  ngOnInit(): void {
    this.filterForm=this.fb.group({
       name: [""],
       businessName: [""],
       date: [""],
    })
    this.getGuestPayment();

    this.searchTextSubject.pipe(debounceTime(700)).subscribe(() => {
      this.getGuestPayment();
    });
  }

  
  downloadCustomData(dateRange: string,content:any) {
    this.selectedDateRangeForDownload= dateRange;
    this.modalRef = this.modalService.open(content, { centered: true,size: 'sm' });
    // this.ngxSmartModalService.getModal('paymentDownloadModal').open();
  }

  downlaodCustomDataInExcel(){
    this.modalRef?.close('selected');
    let apiendpoint = `administrator/payments/getGuestPayments?limit=${this.totalItems}&page=0&businessName=`;

    this.http
      .get<any[]>(`${environment.baseUrl}${apiendpoint}`, {
        responseType: "json",
      })
      .subscribe((response: any) => {
        const payments = response.payments;
        const filteredData = payments.filter(
          (data: { doc: string | number | Date }) => {
            const docDate = new Date(data.doc);
            const currentDate = new Date();

            if (this.selectedDateRangeForDownload === "today") {
              return docDate.toDateString() === currentDate.toDateString();
            } else if (this.selectedDateRangeForDownload === "yesterday") {
              const yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);
              return docDate.toDateString() === yesterday.toDateString();
            } else if (this.selectedDateRangeForDownload === "past7days") {
              const past7Days = new Date();
              past7Days.setDate(past7Days.getDate() - 7);
              return docDate >= past7Days && docDate <= currentDate;
            }

            return true;
          }
        );
        if (filteredData.length === 0) {
          this.errorMessage = "No data available for the selected date range.";
          setTimeout(() => {
            this.errorMessage = "";
          }, 3000);
          return;
        }
        const customData = filteredData.map(
          (data: {
            user: {
              id: any;
              first_name: any;
              last_name: any;
              mobile_no: any;
              email: any;
            };
            bus_id: any;
            order: {
              business: any;
              order_drinks: any;
              order_status: any;
              id: any;
              final_price: any;
            };
            amount: any;
            doc: any;
            refunds: any[];
          }) => ({
            "User ID": data.user.id,
            "First Name": data.user.first_name,
            "Last Name": data.user.last_name,
            "Mobile Number": data.user.mobile_no,
            Email: data.user.email,
            "Business ID": data.bus_id,
            "Business Name": data.order.business.name,
            "Order ID": data.order.id,
            "Final Price": data.order.final_price,
            "Order Status": data.order.order_status,
            // "Refund Type":
            //   data.refunds.length > 0
            //     ? data.refunds[0].metadata.refund_type
            //     : "N/A",
            "Drinks Name": data.order.order_drinks
              .map(
                (drink: { drink_size_options: { drinks: { name: any } } }) =>
                  drink.drink_size_options.drinks.name
              )
              .join(", "),

            Amount: data.amount,
            "Order Date": data.doc,
          })
        );
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(customData);
        const range = XLSX.utils.decode_range(worksheet["!ref"] || "");
        for (let row = range.s.r; row <= range.e.r; row++) {
          for (let col = range.s.c; col <= range.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            const nextCellAddress = XLSX.utils.encode_cell({
              r: row,
              c: col + 1,
            });
            worksheet[cellAddress] = worksheet[nextCellAddress];
          }
        }
        range.e.c -= 1;
        worksheet["!ref"] = XLSX.utils.encode_range(range);

        const workbook: XLSX.WorkBook = {
          Sheets: { data: worksheet },
          SheetNames: ["data"],
        };
        const excelBuffer: any = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const file = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(file, "Guest Payment.xlsx");
      });
  }
  downlaodCustomDataInPDF(){
    this.modalRef?.close('selected');
    let apiendpoint = `administrator/payments/getGuestPayments?limit=${this.totalItems}&page=0&businessName=`;

    this.http
      .get<any[]>(`${environment.baseUrl}${apiendpoint}`, {
        responseType: "json",
      })
      .subscribe((response: any) => {
        const payments = response.payments;
        const filteredData = payments.filter(
          (data: { doc: string | number | Date }) => {
            const docDate = new Date(data.doc);
            const currentDate = new Date();

            if (this.selectedDateRangeForDownload === "today") {
              return docDate.toDateString() === currentDate.toDateString();
            } else if (this.selectedDateRangeForDownload === "yesterday") {
              const yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);
              return docDate.toDateString() === yesterday.toDateString();
            } else if (this.selectedDateRangeForDownload === "past7days") {
              const past7Days = new Date();
              past7Days.setDate(past7Days.getDate() - 7);
              return docDate >= past7Days && docDate <= currentDate;
            }

            return true;
          }
        );
        if (filteredData.length === 0) {
          this.errorMessage = "No data available for the selected date range.";
          setTimeout(() => {
            this.errorMessage = "";
          }, 3000);
          return;
        }
        const customData = filteredData.map(
          (data: {
            user: {
              id: any;
              first_name: any;
              last_name: any;
              mobile_no: any;
              email: any;
            };
            bus_id: any;
            order: {
              business: any;
              order_drinks: any;
              order_status: any;
              id: any;
              final_price: any;
            };
            amount: any;
            doc: any;
            refunds: any[];
          }) => ({
            "Name": data.user.first_name +' '+data.user.last_name,
            "Mobile Number": data.user.mobile_no,
            Email: data.user.email,
            "Business ID": data.bus_id,
            "Business Name": data.order.business.name,
            "Order ID": data.order.id,
            "Final Price": data.order.final_price,
            "Order Status": data.order.order_status,
            // "Refund Type":
            //   data.refunds.length > 0
            //     ? data.refunds[0].metadata.refund_type
            //     : "N/A",
            "Drinks Name": data.order.order_drinks
              .map(
                (drink: { drink_size_options: { drinks: { name: any } } }) =>
                  drink.drink_size_options.drinks.name
              )
              .join(", "),

            Amount: data.amount,
            "Order Date": data.doc,
          })
        );
        // Convert customData to a table format
        const tableBody = [
          Object.keys(customData[0]), // Headers
          ...customData.map((obj:any) => Object.values(obj)) // Data rows
        ];

        const docDefinition = {
          content: [
            { text: 'Payment Details', style: 'header' },
            { text: '\n' },
            {
              table: {
                headerRows: 1,
                widths: [ '15%', '15%', '15%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%'], // Adjust column widths
                body: tableBody
              },
              layout: 'lightHorizontalLines'
            }
          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              color: '#480068',
              margin: [0, 0, 0, 10]
            }
          }
        };

        pdfMake.createPdf(docDefinition).download('Guest Payment.pdf');
       
      });
  }

  getGuestPayment() {
    const pageLimit = 20;

    this.pageService
      .getGuestPayment(pageLimit, this.currentPage, this.userText)
      .subscribe((response: any) => {
        this.originalGuestPayment = response.payments;
        this.totalItems = response.pagination.totalItems;
      });
  }
  pageChange(newPage: number): void {
    this.currentPage = newPage;
    this.getGuestPayment();
  }
  searchUsers() {
    this.searchTextSubject.next();
  }
  getBusinessPayment() {
    this.pageService.getBusinessPayment().subscribe((res: any) => {
      if (res) {
        this.businessPayment = res;
      }
    });
  }

  activateFilter(){
    this.viewFilter = !this.viewFilter;
  }

  submitFilter(){
    console.log(this.filterForm);
    console.log(this.fromDate);
    console.log(this.toDate);
    
    let filters = this.filterForm.value
    let apiendpoint = `administrator/payments/getGuestPayments?limit=${this.totalItems}&page=0${filters.businessName && filters.businessName!=''?'&businessName='+filters.businessName.toLowerCase():''}${filters.name && filters.name!=''?'&userName='+filters.name.toLowerCase():''}${this.fromDate ? '&fromDate='+this.fromDate.day.toString().padStart(2, '0')+'/'+this.fromDate.month.toString().padStart(2, '0')+'/'+this.fromDate.year:''}${this.toDate ? '&toDate='+this.toDate.day.toString().padStart(2, '0')+'/'+this.toDate.month.toString().padStart(2, '0')+'/'+this.toDate.year:''}`;

    this.http
      .get<any[]>(`${environment.baseUrl}${apiendpoint}`, {
        responseType: "json",
      })
      .subscribe((response: any) => {
        this.originalGuestPayment = response.payments;
        this.totalItems = response.pagination.totalItems;
      });
    // let filters = this.filterForm.value
    //   this.filteredGuestPayment = this.originalGuestPayment.filter((record:any) =>{
    //     return (
    //       (filters.name ? record.user.first_name.toLowerCase().includes(filters.name.toLowerCase()) : true) ||
    //       (filters.name ? record.user.last_name.toLowerCase().includes(filters.name.toLowerCase()) : true) ||
    //       (filters.businessName ? record.order.business.name.toLowerCase().includes(filters.businessName.toLowerCase()) : true) ||
    //       (filters.date ? record.doc.startsWith(filters.date) : true)
    //     );
    //   })
  }

  clearFilter(){
    this.viewFilter=false;
    this.fromDate = null;
    this.toDate = null;
    this.filterForm.reset();
    this.getGuestPayment();
  }
  getBase64Image(imgPath: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imgPath;
      img.crossOrigin = 'Anonymous'; // Required if the image is from another domain

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        resolve(canvas.toDataURL('image/png'));
      };

      img.onerror = () => reject('Image load error');
    });
  }
  generateSinglePDF(index:any) {
    let singleRecord = this.originalGuestPayment[index];
    this.getBase64Image('assets/img/login-logo.svg').then((base64:any) => {
    const documentDefinition = {
      content: [
        {
          image: base64,
          width: 100,
          alignment:'center'
        },
        // Main Header
        {
          text: 'Payment Detail',
          style: 'mainHeader',
          margin: [10, 10, 10, 10],
        },

        // User Information Section
        {
          table: {
            widths: ['35%', '65%'],
            body: [
              [{ text: 'User Name:', style: 'label' }, { text: `${singleRecord?.user?.first_name} ${singleRecord?.user?.last_name}`, style: 'value' }],
              [{ text: 'Email:', style: 'label' }, { text: singleRecord?.user?.email, style: 'value' }],
              [{ text: 'Date & Time:', style: 'label' }, { text: this.datePipe.transform(singleRecord?.doc,'dd-MM-yyyy'), style: 'value' }],
            ],
          },
          layout: 'noBorders',
          margin: [0, 0, 0, 10],
        },

        // Payment Details Section (Without Total Payment)
        {
          table: {
            widths: ['50%', '50%'],
            body: [
              [{ text: 'Tips:', style: 'label' }, { text: `$${singleRecord?.order?.tip}`, style: 'value' }],
              [{ text: 'Tax:', style: 'label' }, { text: `$${singleRecord?.order?.tax}`, style: 'value' }],
              [{ text: 'Service Fee:', style: 'label' }, { text: `$${singleRecord?.order?.service}`, style: 'value' }],
              [{ text: 'Refund Type:', style: 'label' }, { text: singleRecord?.refunds && singleRecord.refunds.length > 0 ? singleRecord.refunds[0].metadata?.refund_type : 'NA', style: 'value' }],
              [{ text: 'Total Refunded:', style: 'label' }, { text: `$${singleRecord?.refunds && singleRecord.refunds.length > 0 ? singleRecord.refunds[0].amount : '0.00'}`, style: 'value' }],
            ],
          },
          layout: 'noBorders',
          margin: [0, 0, 0, 10],
        },

        // Drinks Ordered Section
        { text: 'Ordered Drinks', style: 'subHeader', margin: [0, 10, 0, 5] },
        {
          table: {
            headerRows: 1,
            widths: ['70%', '30%'],
            body: [
              [{ text: 'Drink Name', style: 'tableHeader' }, { text: 'Price', style: 'tableHeader' }],
              ...singleRecord?.order?.order_drinks.map((drink: any) => [
                { text: drink?.drink_size_options?.drinks?.name, style: 'value' },
                { text: `$${drink?.drink_size_options?.price}`, style: 'value' }
              ]),
            ],
          },
          layout: 'lightHorizontalLines',
          margin: [0, 0, 0, 10],
        },

        // Final Total Payment - Big and at the Bottom
        {
          text: `Total Payment: $${singleRecord?.amount}`,
          style: 'total',
          margin: [0, 20, 0, 0], // Spaced at the bottom
        }
      ],

      styles: {
        mainHeader: {
          fontSize: 20,
          bold: true,
          color: '#480068',
          fillColor: '#480068', // Primary color as background
          alignment: 'center',
          margin: [0, 0, 0, 10],
          padding: [5, 5, 5, 5],
        },
        subHeader: {
          fontSize: 14,
          bold: true,
          color: '#480068',
        },
        label: {
          fontSize: 12,
          bold: true,
          color: '#480068',
        },
        value: {
          fontSize: 12,
          padding: [5, 5, 5, 5],
        },
        total: {
          fontSize: 16, // Bigger font for total
          bold: true,
          color: '#D32F2F', // Highlighting total payment in red
          alignment: 'right', // Align total amount to the right
        },
        tableHeader: {
          bold: true,
          fontSize: 12,
          fillColor: '#480068', // Table header background
          color: '#FFFFFF',
          alignment: 'left',
          padding: [5, 5, 5, 5],
        },
      }
    };

    pdfMake.createPdf(documentDefinition).download('PaymentDetails.pdf');

  });
  }

  downloadSingleExcel(index: number) {
    let singleRecord = this.originalGuestPayment[index];
  
    // Convert data to a format suitable for Excel
    let excelData = [
      ["Payment Details"], // Title Row
      [], // Empty Row for spacing
      ["User Name", `${singleRecord?.user?.first_name} ${singleRecord?.user?.last_name}`],
      ["Email", singleRecord?.user?.email],
      ["Date & Time", this.datePipe.transform(singleRecord?.doc,'dd-MM-yyyy')],
      ["Tips", `$${singleRecord?.order?.tip}`],
      ["Tax", `$${singleRecord?.order?.tax}`],
      ["Service Fee", `$${singleRecord?.order?.service}`],
      ["Refund Type", singleRecord?.refunds && singleRecord.refunds.length > 0 ? singleRecord.refunds[0].metadata?.refund_type : 'NA'],
      ["Total Refunded", `$${singleRecord?.refunds && singleRecord.refunds.length > 0 ? singleRecord.refunds[0].amount : '0.00'}`],
      [], // Empty Row
      ["Ordered Drinks"], // Section Title
      ["Drink Name", "Price"], // Table Header
      ...singleRecord?.order?.order_drinks.map((drink: any) => [
        drink?.drink_size_options?.drinks?.name,
        `$${drink?.drink_size_options?.price}`
      ]),
      [], // Empty Row
      ["Total Payment", `$${singleRecord?.amount}`] // Final Total at Bottom
    ];
  
    // Create a worksheet
    let ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelData);
  
    // Apply styles (bold for headers)
    let range = XLSX.utils.decode_range(ws['!ref']!);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      let cellRef = XLSX.utils.encode_cell({ r: 0, c: C });
      if (ws[cellRef]) ws[cellRef].s = { font: { bold: true, color: { rgb: "480068" } } };
    }
  
    // Create a workbook and add the worksheet
    let wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "PaymentDetails");
  
    // Save the file
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, `PaymentDetails.xlsx`);
  }

  onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
	}

  isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}
}
