import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { PagesRoutingModule } from "./pages-routing.module";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { TermsConditionComponent } from "./terms-condition/terms-condition.component";
import { UsersComponent } from "./users/users.component";
import { LegalDocumentsComponent } from "./legal-documents/legal-documents.component";
import { HelpRequestComponent } from "./help-request/help-request.component";
import { PaymentComponent } from "./payment/payment.component";
import { PromotionComponent } from "./promotion/promotion.component";
import { NewsletterComponent } from "./newsletter/newsletter.component";
import { TicketComponent } from "./ticket/ticket.component";
import { CreateNewComponent } from "./create-new/create-new.component";
import { GuestNewsletterComponent } from "./guest-newsletter/guest-newsletter.component";
import { ProfileComponent } from "./profile/profile.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BusinessNewsletterComponent } from "./business-newsletter/business-newsletter.component";
import { BusinessSettingComponent } from "./business-setting/business-setting.component";
import { ViewUserComponent } from "./view-user/view-user.component";
import { EditUserComponent } from "./edit-user/edit-user.component";
import { ViewPaymentComponent } from "./view-payment/view-payment.component";
import { EditNewsletterComponent } from "./edit-newsletter/edit-newsletter.component";
import { EditBusinessnewsletterComponent } from "./edit-businessnewsletter/edit-businessnewsletter.component";
import { ViewBusinessUserComponent } from "./view-business-user/view-business-user.component";
import { EditBusinessUserComponent } from "./edit-business-user/edit-business-user.component";
import { DrinkSettingComponent } from "./drink-setting/drink-setting.component";
import { ViewManagerComponent } from "./view-manager/view-manager.component";
import { EditManagerComponent } from "./edit-manager/edit-manager.component";
import { EditEditorsComponent } from "./edit-editors/edit-editors.component";
import { ViewEditorsComponent } from "./view-editors/view-editors.component";
import { VerifyUsersComponent } from "./verify-users/verify-users.component";
import { ViewVerifyUserComponent } from "./view-verify-user/view-verify-user.component";
import { ViewVerifyBusinessComponent } from "./view-verify-business/view-verify-business.component";
import { VerifyDocumentsComponent } from "./verify-documents/verify-documents.component";
import { ViewVerifyDocumentComponent } from "./view-verify-document/view-verify-document.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { EmailBodyComponent } from "./email-body/email-body.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { EditCategoriesComponent } from "./edit-categories/edit-categories.component";
import { EditSubcategoriesComponent } from "./edit-subcategories/edit-subcategories.component";
import { CustomToastComponent } from './custom-toast/custom-toast.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    DashboardComponent,
    PrivacyPolicyComponent,
    TermsConditionComponent,
    UsersComponent,
    LegalDocumentsComponent,
    HelpRequestComponent,
    PaymentComponent,
    PromotionComponent,
    NewsletterComponent,
    TicketComponent,
    CreateNewComponent,
    GuestNewsletterComponent,
    ProfileComponent,
    BusinessNewsletterComponent,
    BusinessSettingComponent,
    ViewUserComponent,
    EditUserComponent,
    ViewPaymentComponent,
    EditNewsletterComponent,
    EditBusinessnewsletterComponent,
    ViewBusinessUserComponent,
    EditBusinessUserComponent,
    DrinkSettingComponent,
    ViewManagerComponent,
    EditManagerComponent,
    EditEditorsComponent,
    ViewEditorsComponent,
    VerifyUsersComponent,
    ViewVerifyUserComponent,
    ViewVerifyBusinessComponent,
    VerifyDocumentsComponent,
    ViewVerifyDocumentComponent,
    ChangePasswordComponent,
    EmailBodyComponent,
    EditCategoriesComponent,
    EditSubcategoriesComponent,
    CustomToastComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    NgbModule,
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    DashboardComponent,
    PrivacyPolicyComponent,
    TermsConditionComponent,
    HeaderComponent,
    UsersComponent,
    LegalDocumentsComponent,
    HelpRequestComponent,
    PaymentComponent,
    PromotionComponent,
    NewsletterComponent,
    TicketComponent,
    CreateNewComponent,
    GuestNewsletterComponent,
    ProfileComponent,
  ],
})
export class PagesModule {}
