<!-- .........content......... -->
<div class="content">
  <div class="row mb-4 mobile-card">
    <div class="cards user-card me-3 zIndex-120">
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">Users</h3>
      </div>
      <div class="search">
        <input
          type="text"
          class="form-control input-area"
          placeholder="search"
          aria-label="invite"
          aria-describedby="basic-addon2"
          (input)="searchUsers()"
          [(ngModel)]="userText"
        />
      </div>
      <div class="manager-list-wrapper">
        <ng-container *ngFor="let user of userList">
          <div
            class="manager-items justify-content-between"
            [ngClass]="{ 'expired-row': user.status == 0 }"
          >
            <div class="d-flex">
              <div class="manager-icon">
                <ng-container
                  *ngIf="
                    user.profile_url && user.profile_url !== '';
                    else elseTemp
                  "
                >
                  <img
                    src="{{ assetURL }}{{ user.profile_url }}"
                    alt="managers"
                    class="real-pic"
                  />
                  <span class="verified-badge" *ngIf="user.isVarified == 1"><img src="../../../assets/img/verified-badge.svg" /></span>
                </ng-container>
                <ng-template #elseTemp>
                  <img src="../../../assets/img/user.png" alt="managers" />
                  <span class="verified-badge-default" *ngIf="user.isVarified == 1"><img src="../../../assets/img/verified-badge.svg" /></span>
                </ng-template>
              </div>
              <div class="ms-3">
                <p class="manager-name">
                  {{ user?.first_name ? user?.first_name : "N/A" }}
                  {{ user?.last_name ? user?.last_name : "N/A" }}
                </p>
                <p class="manager-email">
                  {{ user?.email ? user?.email : "N/A" }}
                </p>
                <!-- <p class="profile bold-text">
                  User Type :{{ user?.userType }} user
                </p> -->
              </div>
            </div>
            <div class="dropdown">
              <a
                class="btn"
                href="javascript:void(0)"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src="../../../assets/img/menu.svg" alt="menu" />
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <a
                    class="dropdown-item dropdown-item-custom"
                    routerLink="/view-user/{{ user.id }}"
                    >View</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item dropdown-item-custom"
                    routerLink="/edit-user/{{ user.id }}"
                    >Edit</a
                  >
                </li>
                <!-- <li>
                  <a
                    class="dropdown-item dropdown-item-custom"
                    (click)="resetPassLink($event)"
                    id="{{ user?.id }}"
                    >Password Reset Link</a
                  >
                </li> -->
                <li>
                  <a
                    class="dropdown-item dropdown-item-custom"
                    (click)="
                      user?.status
                        ? statusManagerDisable($event)
                        : statusManager($event)
                    "
                    id="{{ user?.id }}"
                    >{{ user.status === 1 ? "Disable" : "Enable" }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="cards user-card ms-3">
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">Business</h3>
      </div>
      <div class="search">
        <input
          type="text"
          class="form-control input-area"
          placeholder="search"
          aria-label="invite"
          aria-describedby="basic-addon2"
          (input)="searchBusiness()"
          [(ngModel)]="businessText"
        />
      </div>
      <div class="manager-list-wrapper">
        <ng-container *ngFor="let bus of businessList">
          <div
            class="manager-items justify-content-between"
            [ngClass]="{ 'expired-row': bus.status == 0 }"
          >
            <div class="d-flex">
              <div class="manager-icon">
                <ng-container
                  *ngIf="
                    bus.profile_url && bus.profile_url !== '';
                    else elseTemp1
                  "
                >
                  <img
                    src="{{ assetURL }}{{ bus.profile_url }}"
                    alt="managers"
                    class="real-pic"
                  />
                </ng-container>
                <ng-template #elseTemp1>
                  <img src="../../../assets/img/user.png" alt="managers" />
                </ng-template>
              </div>
              <div class="ms-3">
                <p class="manager-name">{{ bus?.name }}</p>
                <p class="manager-email">{{ bus?.address }}</p>
              </div>
            </div>
            <div class="dropdown">
              <a
                class="btn"
                href="javascript:void(0)"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src="../../../assets/img/menu.svg" alt="menu" />
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <a
                    class="dropdown-item dropdown-item-custom"
                    routerLink="/view-business-user/{{ bus.id }}"
                    >View</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item dropdown-item-custom"
                    routerLink="/edit-business-user/{{ bus.id }}"
                    >Edit</a
                  >
                </li>
                <!-- <li>
                  <a
                    class="dropdown-item dropdown-item-custom"
                    (click)="resetPassLinkBusiness($event)"
                    id="{{ bus?.id }}"
                    >Password Reset Link</a
                  >
                </li> -->
                <li>
                  <a
                    class="dropdown-item dropdown-item-custom"
                    (click)="
                      bus?.status
                        ? statusBusinessDisable($event)
                        : statusBusinessEnable($event)
                    "
                    id="{{ bus?.id }}"
                    >{{ bus?.status === 1 ? "Disable" : "Enable" }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
