import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { PageServiceService } from "../page-service.service";

@Component({
  selector: "app-edit-newsletter",
  templateUrl: "./edit-newsletter.component.html",
  styleUrls: ["./edit-newsletter.component.css"],
})
export class EditNewsletterComponent implements OnInit {
  //@ts-ignore
  generalForm: FormGroup;
  isSubmitted: any = false;
  newsletterId: any;
  guestNews: any = [];

  constructor(
    public pageService: PageServiceService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getGuestNewsletter();
    setTimeout(() => {
      this.fetchdata();
    }, 1000);

    this.generalForm = this.formBuilder.group({
      title: ["", Validators.required],
      description: ["", Validators.required],
    });
  }
  fetchdata() {
    this.newsletterId = this.activatedRoute.snapshot.paramMap.get("id");
    let newsletter = this.guestNews.find(
      (x: { id: any }) => x.id == this.newsletterId
    );
    this.generalForm.get("title")?.setValue(newsletter.title);
    this.generalForm.get("description")?.setValue(newsletter.body);
  }
  get f() {
    return this.generalForm.controls;
  }
  getGuestNewsletter() {
    this.pageService.getGuestNewsletter().subscribe((res: any) => {
      if (res) {
        this.guestNews = res;
      }
      this.pageService.loading.emit(false);
    });
  }
  updateNewsletter() {
    this.isSubmitted = true;
    if (this.generalForm.invalid) {
      return;
    }
    this.pageService.loading.emit(true);
    const body = {
      id: +this.newsletterId,
      title: this.f.title.value,
      body: this.f.description.value,
      role: 3,
    };
    this.pageService.updateNewsletter(body).subscribe((res: any) => {
      if (res) {
        this.isSubmitted = false;
        this.generalForm.reset();
        window.location.replace("/Newsletter");
        this.pageService.loading.emit(false);
      }
    });
  }
}
