import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthModule } from "./auth/auth.module";
import { PagesModule } from "./pages/pages.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Interceptor } from "./pages/shared.interceptor";
import { ErrorInterceptor } from "./pages/error.interceptor";
import { NgxLoadingModule } from "ngx-loading";
import { NgbToastModule } from "@ng-bootstrap/ng-bootstrap";
import { PagenotfoundComponent } from "./auth/pagenotfound/pagenotfound.component";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
@NgModule({
  declarations: [AppComponent,PagenotfoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxLoadingModule.forRoot({}),
    AuthModule,
    PagesModule,
    NgbModule,
    NgbToastModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
