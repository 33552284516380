<div class="sidebar" id="sideNavbar">
  <div class="logo-wrapper">
    <a
      routerLink="/dashboard"
      style="display: flex; justify-content: center; align-items: center"
    >
      <img
        src="../../../assets/img/login-logo.svg"
        alt="logo"
        class="logo-sidebar"
      />
    </a>
    <a class="closebtn" (click)="closeNav()"> &times; </a>
  </div>
  <ul class="list">
    <li
      class="sidebar-item"
      routerLinkActive="active"
      *ngIf="data.role == 1 || data.role == 6"
    >
      <a routerLink="/dashboard" (click)="closeNav()">
        <img src="../../../assets/img/admin.svg" alt="admin" />
        <span class="list-item">Admin</span>
      </a>
    </li>
    <li
      class="sidebar-item"
      routerLinkActive="active"
      *ngIf="data.role == 1 || data.role == 4 || data.role == 6"
    >
      <a routerLink="/users" (click)="closeNav()">
        <img src="../../../assets/img/users.svg" alt="user" />
        <span class="list-item">Users</span>
      </a>
    </li>
    <li
      class="sidebar-item"
      routerLinkActive="active"
      *ngIf="data.role == 1 || data.role == 6"
    >
      <a routerLink="/verify-users" (click)="closeNav()">
        <img src="../../../assets/img/userVerification.svg" alt="verifyUsers" />
        <span class="list-item">Verify Users</span>
      </a>
    </li>
    <!-- <li
      class="sidebar-item"
      routerLinkActive="active"
      *ngIf="data.role == 1 || data.role == 6"
    >
      <a routerLink="/verify-docments" (click)="closeNav()" >
        <img src="../../../assets/img/verification.svg" alt="verifyDocuments" />
        <span class="list-item">Verify Documents</span>
      </a>
    </li> -->
    <li
      class="sidebar-item"
      routerLinkActive="active"
      *ngIf="data.role == 1 || data.role == 6"
    >
      <a routerLink="/legal-documents" (click)="closeNav()">
        <img src="../../../assets/img/document.svg" alt="document" />
        <span class="list-item">Legal Documents</span>
      </a>
    </li>
    <li
      class="sidebar-item"
      routerLinkActive="active"
      *ngIf="data.role == 1 || data.role == 4 || data.role == 6"
    >
      <a routerLink="/help-center" (click)="closeNav()">
        <img src="../../../assets/img/help-center.svg" alt="help" />
        <span class="list-item">Help Center</span>
      </a>
    </li>
    <li
      class="sidebar-item"
      routerLinkActive="active"
      *ngIf="data.role == 1 || data.role == 6"
    >
      <a routerLink="/payment" (click)="closeNav()">
        <img src="../../../assets/img/payment.svg" alt="payment" />
        <span class="list-item">Payments</span>
      </a>
    </li>
    <li
      class="sidebar-item"
      routerLinkActive="active"
      *ngIf="
        data.role == 1 || data.role == 4 || data.role == 5 || data.role == 6
      "
    >
      <a routerLink="/promotion" (click)="closeNav()">
        <img src="../../../assets/img/promotion.svg" alt="Promotions" />
        <span class="list-item">Promotions</span>
      </a>
    </li>
    <li
      class="sidebar-item"
      routerLinkActive="active"
      *ngIf="
        data.role == 1 || data.role == 4 || data.role == 5 || data.role == 6
      "
    >
      <a routerLink="/Newsletter" (click)="closeNav()">
        <img src="../../../assets/img/newsletter.svg" alt="Newslatter" />
        <span class="list-item">Newsletter</span>
      </a>
    </li>

    <li
      class="sidebar-item"
      routerLinkActive="active"
      *ngIf="data.role == 1 || data.role == 6"
    >
      <a routerLink="/business-setting" (click)="closeNav()">
        <img src="../../../assets/img/settings.svg" alt="business-setting" />
        <span class="list-item">Drinks Options</span>
      </a>
    </li>
  </ul>
</div>
