<!-- .........content......... -->
<div class="content">
  <div class="row mb-4">
    <div class="cards invite-card">
      <div
        class="cards-header d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <a routerLink="/legal-documents">
            <img src="../../../assets/img/left.png" alt="back" width="28px" />
          </a>
          <h3 class="cards-heading mx-3">Profile</h3>
        </div>
      </div>

      <div class="mt-5 w-100">
        <div class="profile-pic-detail">
          <ng-container
            *ngIf="
              userData.profile_url && userData.profile_url !== '';
              else elseTemp1
            "
          >
            <img src="{{ assetURL }}{{ userData.profile_url }}" alt="profile" />
          </ng-container>
          <ng-template #elseTemp1>
            <img src="../../../assets/img/user.png" alt="profile" />
          </ng-template>
        </div>
        <div class="tag guest-tag">{{ userData?.name }} ({{ userData?.role }})</div>
      </div>

      <div class="profile-card">
        <p class="name-profile">
          {{ userData?.first_name }} {{ userData?.last_name }}
        </p>
        <p class="name-profile email-profile">{{ userData?.email }}</p>

        <div class="d-flex justify-content-between mt-4 doc">
          <div class="cards wid-45 py-4 px-5 d-flex align-items-center">
            <div class="manager-icon me-4">
              <img
                src="../../../assets/img/document-blue.svg"
                alt="view document"
              />
            </div>
            <p class="email-profile email-min-wid">Document Front</p>

            <div class="profile-pic-detail pic-small">
              <img
                [src]="userData?.doc_pic_front ? assetURL + userData.doc_pic_front : '../../../assets/img/license.png'"
                alt="doc pic front"
                (click)="showEnlargedView(userData?.doc_pic_front)"
              />
            </div>
          </div>
          <div class="cards wid-45 py-4 px-5 d-flex align-items-center">
            <div class="manager-icon me-4">
              <img
                src="../../../assets/img/document-blue.svg"
                alt="view document"
              />
            </div>
            <p class="email-profile email-min-wid">Document Back</p>

            <div class="profile-pic-detail pic-small">
              <img
                [src]="userData?.doc_pic_back ? assetURL + userData.doc_pic_back : '../../../assets/img/license.png'"
                alt="doc pic back"
                (click)="showEnlargedView(userData?.doc_pic_back)"
              />
            </div>
          </div>

          <div
            class="enlarged-view"
            [ngStyle]="enlargedViewStyle"
            *ngIf="showEnlarged"
          >
            <span class="close-icon" (click)="closeEnlargedView()"
              >&times;</span
            >
            <img
              class="enlargeimg"
              [src]="enlargedImageUrl ? assetURL + enlargedImageUrl : '../../../assets/img/license.png'"
              alt="Enlarged Image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
