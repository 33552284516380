<div class="content card">
  <div class="d-flex align-items-center pb-4">
    <a routerLink="/users">
      <img src="../../../assets/img/left.png" alt="back" width="28px" />
    </a>

    <h3 class="cards-heading mx-3">Edit User</h3>
  </div>
  <div class="row mb-4 p-3">
    <div class="h-10 w-10">
      <img
        [src]="guestIdImg?.profile_url ? assetURL + guestIdImg.profile_url : '../../../assets/img/user.png'"
        alt="Users"
        class="real-pic"
      />
    </div>
  </div>
  <div class="cards user-card me-3">
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">User ID</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="User name"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ guestIdImg?.id }}"
        disabled
      />
    </div>
    <form [formGroup]="generalForm" (ngSubmit)="updateUserData()">
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">User Name</h3>
      </div>
      <div class="padding">
        <input
          type="text"
          class="form-control input-area"
          placeholder="User name"
          aria-label="invite"
          aria-describedby="basic-addon2"
          formControlName="username"
        />
      </div>
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">First Name</h3>
      </div>
      <div class="padding">
        <input
          type="text"
          class="form-control input-area"
          placeholder="First Name"
          aria-label="invite"
          aria-describedby="basic-addon2"
          formControlName="fname"
        />
      </div>
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">Last Name</h3>
      </div>
      <div class="padding">
        <input
          type="text"
          class="form-control input-area"
          placeholder="Last Name"
          aria-label="invite"
          aria-describedby="basic-addon2"
          formControlName="lname"
        />
      </div>
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">Email</h3>
      </div>
      <div class="padding">
        <input
          type="text"
          class="form-control input-area"
          placeholder="Email"
          aria-label="invite"
          aria-describedby="basic-addon2"
          formControlName="email"
        />
      </div>
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">Address</h3>
      </div>
      <div class="padding">
        <input
          type="text"
          class="form-control input-area"
          placeholder="Address"
          aria-label="invite"
          aria-describedby="basic-addon2"
          formControlName="address"
        />
      </div>
      <div class="row mb-4 p-3">
        <h3 class="cards-heading">License Certificate</h3>
        <div class="h-10 w-10">
          <img
            [src]="guestIdImg?.doc_pic_front ? assetURL + guestIdImg.doc_pic_front : '../../../assets/img/license.png'"
            alt="Users"
            class="real-pic"
            (click)="showEnlargedView(guestIdImg?.doc_pic_front)"
          />
        </div>
        <div class="h-10 w-10">
          <img
            [src]="guestIdImg?.doc_pic_back ? assetURL + guestIdImg.doc_pic_back : '../../../assets/img/license.png'"
            alt="Users"
            class="real-pic"
            (click)="showEnlargedView(guestIdImg?.doc_pic_back)"
          />
        </div>
        <div
          class="enlarged-view"
          [ngStyle]="enlargedViewStyle"
          *ngIf="showEnlarged"
        >
          <span class="close-icon" (click)="closeEnlargedView()">&times;</span>
          <img
            class="enlargeimg"
            [src]="enlargedImageUrl ? assetURL + enlargedImageUrl : '../../../assets/img/license.png'"
            alt="Enlarged Image"
          />
        </div>
      </div>
      <button
        type="submit"
        class="btn invite-btn btn-55-height btn-170-wid mt-5"
      >
        Update
      </button>
    </form>
  </div>
</div>
