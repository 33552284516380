import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PageServiceService } from "../page-service.service";

@Component({
  selector: "app-view-payment",
  templateUrl: "./view-payment.component.html",
  styleUrls: ["./view-payment.component.css"],
})
export class ViewPaymentComponent implements OnInit {
  guestPayment: any;
  paymentId: any;
  paymentIdData: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    public pageService: PageServiceService
  ) {}

  ngOnInit(): void {
    this.getGuestPayment();
  }

  getGuestPayment() {
    this.paymentId = this.activatedRoute.snapshot.paramMap.get("id");
    this.pageService
      .getGuestPaymentById(this.paymentId)
      .subscribe((res: any) => {
        if (res) {
          this.paymentIdData = res;
        }
      });
  }
}
