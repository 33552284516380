import { Component, OnInit } from "@angular/core";
import { PageServiceService } from "../page-service.service";

@Component({
  selector: "app-newsletter",
  templateUrl: "./newsletter.component.html",
  styleUrls: ["./newsletter.component.css"],
})
export class NewsletterComponent implements OnInit {
  guestNews: any = [];
  businessNews: any = [];

  constructor(public pageService: PageServiceService) {}

  ngOnInit(): void {
    this.pageService.loading.emit(true);
    this.getBusinessNewsletter();
    this.getGuestNewsletter();
  }
  getGuestNewsletter() {
    this.pageService.getGuestNewsletter().subscribe((res: any) => {
      if (res) {
        this.guestNews = res;
      }
      this.pageService.loading.emit(false);
    });
  }
  getBusinessNewsletter() {
    this.pageService.getBusinessNewsletter().subscribe((res: any) => {
      if (res) {
        this.businessNews = res;
      }
      this.pageService.loading.emit(false);
    });
  }
  deleteNewsletter(event: any) {
    const clickedElementId = event.target.id;
    this.pageService
      .deleteNewsletter(clickedElementId)
      .subscribe((res: any) => {
        if (res) {
        }
        window.location.reload();
        this.pageService.loading.emit(false);
      });
  }
}
