<div class="content card">
  <div class="d-flex align-items-center pb-4">
    <a routerLink="/users">
      <img src="../../../assets/img/left.png" alt="back" width="28px" />
    </a>

    <h3 class="cards-heading mx-3">View User</h3>
  </div>
  <div class="row mb-4 p-3">
    <div class="h-10 w-10 position-relative">
      <img
        [src]="guestIdData?.profile_url ? assetURL + guestIdData.profile_url : '../../../assets/img/user.png'"
        alt="User profile pic"
        class="real-pic"
      />
      <span class="verified-badge" [ngClass]="{'verified-badge' : guestIdData?.profile_url,'verified-badge-default' : !guestIdData?.profile_url}" *ngIf="guestIdData.isVarified == 1"><img src="../../../assets/img/verified-badge.svg" /></span>

    </div>
  </div>

  <div class="cards user-card me-3">
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">User ID</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="User Name"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ guestIdData?.id }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">User Name</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="User Name"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ guestIdData?.user_name }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">First Name</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="First Name"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ guestIdData?.first_name }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Last Name</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="Last Name"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ guestIdData?.last_name }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Email</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="Email"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ guestIdData?.email }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Address</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="Address"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ guestIdData?.address1 }}"
        disabled
      />
    </div>
    <div class="row mb-4 p-3">
      <h3 class="cards-heading">License Certificate</h3>

      <div class="h-10 w-10">
        <img
          [src]="guestIdData?.doc_pic_front ? assetURL + guestIdData.doc_pic_front : '../../../assets/img/license.png'"
          alt="License certificate"
          class="real-pic"
          (click)="showEnlargedView(guestIdData?.doc_pic_front)"
        />
      </div>
      <div class="h-10 w-10">
        <img
          [src]="guestIdData?.doc_pic_back ? assetURL + guestIdData.doc_pic_back : '../../../assets/img/license.png'"
          alt="License certificate"
          class="real-pic"
          (click)="showEnlargedView(guestIdData?.doc_pic_back)"
        />
      </div>
      <div
        class="enlarged-view"
        [ngStyle]="enlargedViewStyle"
        *ngIf="showEnlarged"
      >
        <span class="close-icon" (click)="closeEnlargedView()">&times;</span>
        <img
          class="enlargeimg"
          [src]="enlargedImageUrl ? assetURL + enlargedImageUrl : '../../../assets/img/license.png'"
          alt="Enlarged Image"
        />
      </div>
    </div>
  </div>
</div>
