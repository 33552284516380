import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { PageServiceService } from "src/app/pages/page-service.service";
import { AuthServiceService } from "../auth-service.service";

@Component({
  selector: "app-enter-email",
  templateUrl: "./enter-email.component.html",
  styleUrls: ["./enter-email.component.css"],
})
export class EnterEmailComponent implements OnInit {
  // @ts-ignore
  emailForm: FormGroup;
  isSubmitted = false;
  errorMessage: string = "";
  constructor(
    public router: Router,
    public pageService: PageServiceService,
    private formBuilder: FormBuilder,
    public authService: AuthServiceService
  ) {}
  get formControls() {
    return this.emailForm.controls;
  }
  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });
  }
  requestedToken() {
    this.isSubmitted = true;
    this.errorMessage = "";
    if (this.emailForm.invalid) {
      return;
    }
    this.pageService.loading.emit(true);
    let body = {
      email: this.formControls.email.value,
    };
    this.pageService.requestToken(body).subscribe(
      (res: any) => {
        if (res) {
          this.router.navigate(["/forgot-password"]);
          this.pageService.loading.emit(false);
        }
      },
      (err) => {
        this.errorMessage = err?.error?.error;
        console.log("error message", this.errorMessage);
        console.log("error", err);
      }
    );
  }
}
