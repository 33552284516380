import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { PageServiceService } from "../page-service.service";
import { FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "app-view-verify-business",
  templateUrl: "./view-verify-business.component.html",
  styleUrls: ["./view-verify-business.component.css"],
})
export class ViewVerifyBusinessComponent implements OnInit {
  //@ts-ignore
  userRejectForm: FormGroup;
  baseUrl: string = environment.baseUrl;
  assetURL: string = environment.assetURL;
  businessId: any;
  businessData: any = [];
  businessIdData: any = [];
  showInput: boolean = false;
  showSubmitButton: boolean = false;
  isSubmitted: any = false;
  userRejectId: any;
  showEnlarged = false;
  enlargedShow = false;
  enlargedImageUrl: string = "";
  enlargedViewStyle: any = {};
  successMessage: string = "";
  showToast: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public pageService: PageServiceService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getBusinessUserData();
    setTimeout(() => {
      this.fetchData();
    }, 2000);
    this.userRejectForm = this.formBuilder.group({
      reajectReason: ["", Validators.required],
    });
  }
  fetchData() {
    this.businessId = this.activatedRoute.snapshot.paramMap.get("id");
    this.businessIdData = this.businessData.find(
      (x: { id: any }) => x.id == this.businessId
    );
    this.userRejectId = this.businessId;
  }
  getBusinessUserData() {
    this.pageService.getUnverifyBusiness().subscribe((res: any) => {
      if (res) {
        this.businessData = res;
      }
      this.pageService.loading.emit(false);
    });
  }
  verifyBusiness(event: any) {
    this.pageService
      .getVerifyBusiness(event.target.id)
      .subscribe((res: any) => {
        if (res) {
          const navigationExtras = {
            queryParams: { successMessage: "Business Verify Sucessfully." },
          };
          this.router.navigate(["/verify-users"], navigationExtras);
        }
        this.pageService.loading.emit(false);
      });
  }
  toggleInput(event: any) {
    // this.userRejectId = event.target.id;
    this.showInput = !this.showInput;
    this.showSubmitButton = true;
  }
  get mf() {
    return this.userRejectForm?.controls;
  }
  userReject() {
    this.isSubmitted = true;
    this.userRejectForm.markAllAsTouched();
    if (this.userRejectForm.invalid) {
      return;
    }
    const body = {
      rejection_reason: this.mf?.reajectReason.value,
    };
    this.pageService
      .getRejectBusiness(this.userRejectId, body)
      .subscribe((res: any) => {
        if (res) {
          const navigationExtras = {
            queryParams: { successMessage: "Business Rejected." },
          };
          this.router.navigate(["/verify-users"], navigationExtras);
        }
      });
  }
  showEnlargedView(imageUrl: string): void {
    this.enlargedImageUrl = imageUrl;
    this.showEnlarged = true;
    this.enlargedViewStyle = {
      background: "rgba(0, 0, 0, 0.7)",
      position: "fixed",
      top: "0",
      left: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "990",
      width: "100%",
      height: "100%",
    };
  }
  closeEnlargedView(): void {
    this.showEnlarged = false;
    this.enlargedViewStyle = {};
  }
}
