import { Component, OnInit } from "@angular/core";
import { PageServiceService } from "../page-service.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.css"],
})
export class EditUserComponent implements OnInit {
  //@ts-ignore
  generalForm: FormGroup;
  isSubmitted: any = false;
  baseUrl: string = environment.baseUrl;
  assetURL: string = environment.assetURL;
  guestId: any;
  guestData: any = [];
  guestIdImg: any;
  guestIdData: any;
  isDisabled: boolean = true;
  showEnlarged = false;
  enlargedShow = false;
  enlargedImageUrl: string = "";
  enlargedViewStyle: any = {};

  constructor(
    public pageService: PageServiceService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getGuestUserData();
    setTimeout(() => {
      this.fetchData();
    }, 2000);
    this.generalForm = this.formBuilder.group({
      fname: ["", Validators.required],
      lname: ["", Validators.required],
      username: ["", Validators.required],
      email: ["", Validators.required],
      address: ["", Validators.required],
    });
  }

  get f() {
    return this.generalForm.controls;
  }
  fetchData() {
    this.guestId = this.activatedRoute.snapshot.paramMap.get("id");
    this.guestIdData = this.guestData.find(
      (x: { id: any }) => x.id == this.guestId
    );

    this.generalForm.get("fname")?.setValue(this.guestIdData.first_name);
    this.generalForm.get("lname")?.setValue(this.guestIdData.last_name);
    this.generalForm.get("username")?.setValue(this.guestIdData.user_name);
    this.generalForm.get("email")?.setValue(this.guestIdData.email);
    this.generalForm.get("address")?.setValue(this.guestIdData.address1);
    this.generalForm.get("email")?.disable();
    this.generalForm.get("username")?.disable();

    this.guestIdImg = this.guestData.find(
      (x: { id: any }) => x.id == this.guestId
    );
  }

  getGuestUserData() {
    this.pageService.getusers().subscribe((res: any) => {
      if (res) {
        this.guestData = res;
      }
      this.pageService.loading.emit(false);
    });
  }

  updateUserData() {
    this.isSubmitted = true;
    if (this.generalForm.invalid) {
      return;
    }
    this.pageService.loading.emit(true);
    const body = {
      id: +this.guestId,
      user_name: this.f.username.value,
      first_name: this.f.fname.value,
      last_name: this.f.lname.value,
      email: this.f.email.value,
      address1: this.f.address.value,
      country_code: this.guestIdData.country_code
        ? this.guestIdData.country_code
        : "",
      mobile_no: this.guestIdData.mobile_no ? this.guestIdData.mobile_no : "",
      favorites: this.guestIdData.favorites ? this.guestIdData.favorites : "",
      lisence: this.guestIdData.lisence ? this.guestIdData.lisence : "",
      address2: this.guestIdData.address2 ? this.guestIdData.address2 : "",
      country: this.guestIdData.country ? this.guestIdData.country : "",
      city: this.guestIdData.city ? this.guestIdData.city : "",
      state: this.guestIdData.state ? this.guestIdData.state : "",
      zipcode: this.guestIdData.zipcode ? this.guestIdData.zipcode : "",
      doc_pic_front: this.guestIdData.doc_pic_front
        ? this.guestIdData.doc_pic_front
        : "",
      doc_pic_back: this.guestIdData.doc_pic_back
        ? this.guestIdData.doc_pic_back
        : "",
      profile_url: this.guestIdData.profile_url
        ? this.guestIdData.profile_url
        : "",
      dob: this.guestIdData.dob ? this.guestIdData.dob : "",
      doc_expires_on: this.guestIdData.doc_expires_on
        ? this.guestIdData.doc_expires_on
        : "1999-10-30T19:00:00.000Z",
      latitude: this.guestIdData.latitude ? this.guestIdData.latitude : "",
      longitude: this.guestIdData.longitude ? this.guestIdData.longitude : "",
      step: this.guestIdData.step ? this.guestIdData.step : "",
    };
    this.pageService.updateGuestUser(body).subscribe((res: any) => {
      if (res) {
        this.isSubmitted = false;
        window.location.replace("/users");
        this.generalForm.reset();
        this.pageService.loading.emit(false);
      }
    });
  }
  showEnlargedView(imageUrl: string): void {
    this.enlargedImageUrl = imageUrl;
    this.showEnlarged = true;
    this.enlargedViewStyle = {
      background: "rgba(0, 0, 0, 0.7)",
      position: "fixed",
      top: "0",
      left: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "990",
      width: "100%",
      height: "100%",
    };
  }
  closeEnlargedView(): void {
    this.showEnlarged = false;
    this.enlargedViewStyle = {};
  }
}
