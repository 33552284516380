import { Component, OnInit } from "@angular/core";
import { PageServiceService } from "../page-service.service";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-edit-subcategories",
  templateUrl: "./edit-subcategories.component.html",
  styleUrls: ["./edit-subcategories.component.css"],
})
export class EditSubcategoriesComponent implements OnInit {
  //@ts-ignore
  generalForm: FormGroup;
  isSubmitted: any = false;
  categoriesList: any = [];
  subCategoryId: any;
  guestNews: any = [];
  constructor(
    public pageService: PageServiceService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getSubCategorie();
    setTimeout(() => {
      this.fetchdata();
    }, 1000);

    this.generalForm = this.formBuilder.group({
      categories: ["", Validators.required],
      categ: ["", Validators.required],
    });
  }
  get f() {
    return this.generalForm.controls;
  }
  getSubCategorie() {
    this.pageService.getSubCategories().subscribe((res: any) => {
      if (res) {
        this.categoriesList = res;
      }
    });
  }
  fetchdata() {
    this.subCategoryId = this.activatedRoute.snapshot.paramMap.get("id");
    let subCategory = this.categoriesList.find(
      (x: { id: any }) => x.id == this.subCategoryId
    );

    this.generalForm.get("categories")?.setValue(subCategory.name);
    this.generalForm.get("categ")?.setValue(subCategory.cat_name);
  }
  updateSubCategory() {
    const body = {
      name: this.f.categories.value,
    };

    this.pageService
      .updateSubCategory(+this.subCategoryId, body)
      .subscribe((res: any) => {
        if (res) {
          this.isSubmitted = false;
          this.generalForm.reset();
          window.location.replace("/business-setting");
          this.pageService.loading.emit(false);
        }
      });
  }
}
