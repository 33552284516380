import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { EnterEmailComponent } from './enter-email/enter-email.component';
import { EnterOtpComponent } from './enter-otp/enter-otp.component';

@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent, EnterEmailComponent, EnterOtpComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    LoginComponent
  ]
})
export class AuthModule { }
