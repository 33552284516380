import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { PageServiceService } from "../page-service.service";

@Component({
  selector: "app-view-verify-document",
  templateUrl: "./view-verify-document.component.html",
  styleUrls: ["./view-verify-document.component.css"],
})
export class ViewVerifyDocumentComponent implements OnInit {
  baseUrl: string = environment.baseUrl;
  assetURL: string = environment.assetURL;
  businessId: any;
  businessData: any = [];
  businessIdData: any = [];
  liq_img!: string;
  bus_img!: string;
  bus: any;
  showEnlarged = false;
  enlargedViewStyle: any = {};
  constructor(
    private activatedRoute: ActivatedRoute,
    public pageService: PageServiceService
  ) {}

  ngOnInit(): void {
    this.getBusinessDocData();
    setTimeout(() => {
      this.fetchData();
    }, 1000);
  }
  fetchData() {
    this.businessId = this.activatedRoute.snapshot.paramMap.get("id");
    this.businessIdData = this.businessData.find(
      (x: { id: any }) => x.id == this.businessId
    );
    console.log(this.businessIdData);
    this.bus_img = this.businessIdData.bus_cert;
    this.liq_img = this.businessIdData.liq_cert;
  }
  getBusinessDocData() {
    this.pageService.getverifyBusinessDoc().subscribe((res: any) => {
      if (res) {
        this.businessData = res.data;
      }
      this.pageService.loading.emit(false);
    });
  }
  verifyBusinessDoc(event: any, status: any) {
    const body = {
      verify_status: status,
    };
    this.pageService
      .getVerifyBusinessDoc(event.target.id, body)
      .subscribe((res: any) => {
        if (res) {
          window.location.replace("/verify-docments");
        }
        this.pageService.loading.emit(false);
      });
  }

  showEnlargedView(): void {
    this.showEnlarged = true;
    this.enlargedViewStyle = {
      background: "rgba(0, 0, 0, 0.7)",
      position: "fixed",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "9999",
    };
  }
  closeEnlargedView(): void {
    this.showEnlarged = false;
    this.enlargedViewStyle = {};
  }
}
