<!-- .........content......... -->
<div class="content">
  <div class="row mb-4">
    <div class="cards user-card me-30">
      <div
        class="cards-header d-flex justify-content-between align-items-center"
      >
        <h3 class="cards-heading">Users Documents</h3>
      </div>
      <div class="search">
        <input
          type="text"
          class="form-control input-area"
          placeholder="search"
          aria-label="invite"
          aria-describedby="basic-addon2"
          (input)="searchUsers()"
          [(ngModel)]="userText"
        />
      </div>
      <div class="manager-list-wrapper">
        <ng-container *ngFor="let user of userList">
          <div class="manager-items justify-content-between">
            <div class="d-flex">
              <div class="manager-icon">
                <ng-container
                  *ngIf="
                    user.profile_url && user.profile_url !== '';
                    else elseTemp
                  "
                >
                  <img
                    src="{{ assetURL }}{{ user.profile_url }}"
                    alt="user profile pic"
                    class="real-pic"
                  />
                </ng-container>
                <ng-template #elseTemp>
                  <img
                    src="../../../assets/img/user.png"
                    alt="user profile pic"
                    class="profile-img"
                  />
                </ng-template>
              </div>
              <div class="ms-3 document-width">
                <p class="manager-name">
                  {{ user?.first_name ? user?.first_name : "N/A" }}
                  {{ user?.last_name ? user?.last_name : "N/A" }}
                </p>
                <p class="manager-email">
                  {{ user?.email ? user?.email : "N/A" }}
                </p>
              </div>
            </div>
            <button
              (click)="openProfile(user, 'user')"
              type="button"
              class="btn view-btn btn-height-35 ms-2"
            >
              View Documents
            </button>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="cards user-card ms-3">
      <div
        class="cards-header d-flex justify-content-between align-items-center"
      >
        <h3 class="cards-heading">Businessess Documents</h3>
      </div>
      <div class="search">
        <input
          type="text"
          class="form-control input-area"
          placeholder="search"
          aria-label="invite"
          aria-describedby="basic-addon2"
          (input)="searchBusiness()"
          [(ngModel)]="businessText"
        />
      </div>
      <div class="manager-list-wrapper">
        <ng-container *ngFor="let bus of businessList">
          <div class="manager-items justify-content-between">
            <div class="d-flex">
              <div class="manager-icon">
                <ng-container
                  *ngIf="
                    bus.profile_url && bus.profile_url !== '';
                    else elseTemp1
                  "
                >
                  <img
                    src="{{ assetURL }}{{ bus.profile_url }}"
                    alt="business profile pic"
                    class="real-pic"
                  />
                </ng-container>
                <ng-template #elseTemp1>
                  <img
                    src="../../../assets/img/document-blue.svg"
                    alt="view document"
                  />
                </ng-template>
              </div>
              <div class="ms-3 document-width">
                <p class="manager-name">{{ bus?.name }}</p>
                <p class="manager-email">{{ bus?.address }}</p>
              </div>
            </div>
            <button
              (click)="openProfile(bus, 'business')"
              type="button"
              class="btn view-btn btn-height-35 ms-2"
            >
              View Documents
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
