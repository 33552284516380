import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageServiceService } from 'src/app/pages/page-service.service';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.css']
})
export class PagenotfoundComponent implements OnInit {

  isLoggedin:boolean=false;
  userDetails:any;
  constructor(
    private router:Router,
    private pageService:PageServiceService
  ) { }

  ngOnInit(): void {
    this.pageService.userSubject.subscribe(response =>{
      if(Object.keys(response).length > 0){
        this.isLoggedin = true;
        this.userDetails= response;
      }
    });
  }

  goToHome(){
    if(this.userDetails){
      if (this.userDetails?.role == 1 || this.userDetails?.role == 6) {
        this.router.navigate(["/dashboard"]);
      } else if (this.userDetails?.role == 4) {
        this.router.navigate(["/users"]);
      } else if (this.userDetails?.role == 5) {
        this.router.navigate(["/promotion"]);
      }
    }else{
      this.router.navigate(["/login"]);
    }
  }
}
