import { Component, OnInit } from "@angular/core";
import { PageServiceService } from "../page-service.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "app-legal-documents",
  templateUrl: "./legal-documents.component.html",
  styleUrls: ["./legal-documents.component.css"],
})
export class LegalDocumentsComponent implements OnInit {
  userList: Array<any> = [];
  businessList: any = [];
  userListData: Array<any> = [];
  businessListData: any = [];
  assetURL: string = environment.assetURL;
  userText: any = "";
  businessText: any = "";

  constructor(public pageService: PageServiceService, public router: Router) {
    this.pageService.loading.emit(true);
  }

  ngOnInit(): void {
    this.getusers();
    this.getBusiness();
  }
  getusers() {
    this.pageService.getusers().subscribe((res: any) => {
      if (res) {
        this.userList = res;
        this.userListData = res;
      }
    });
  }
  getBusiness() {
    this.pageService.getBusiness().subscribe((res: any) => {
      if (res) {
        this.businessList = res.businesses;
        this.businessListData = res.businesses;
      }
      this.pageService.loading.emit(false);
    });
  }
  searchUsers() {
    const data = this.userListData.filter((v, i) => {
      return (
        v["first_name"]?.toLowerCase().includes(this.userText.toLowerCase()) ||
        v["last_name"]?.toLowerCase().includes(this.userText.toLowerCase()) ||
        v.email?.toLowerCase().includes(this.userText.toLowerCase())
      );
    });
    this.userList = data;
  }
  searchBusiness() {
    const data = this.businessListData.filter((v: any, i: any) => {
      return (
        v["address"]?.toLowerCase().includes(this.businessText.toLowerCase()) ||
        v["name"]?.toLowerCase().includes(this.businessText.toLowerCase())
      );
    });
    this.businessList = data;
  }
  openProfile(data: any, type: string) {
    data["type"] = type;
    this.router.navigate(["/legal-documents/profile"], {
      queryParams: { user: JSON.stringify(data) },
    });
  }
}
