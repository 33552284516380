<div class="content card">
  <div class="d-flex align-items-center pb-4">
    <a routerLink="/users">
      <img src="../../../assets/img/left.png" alt="back" width="28px" />
    </a>

    <h3 class="cards-heading mx-3">View Business</h3>
  </div>
  <div class="row mb-4">
    <div class="h-10 w-10">
      <img
        [src]="businessIdData?.user?.profile_url ? assetURL + businessIdData.user.profile_url : '../../../assets/img/user.png'"
        alt="Business profile pic"
        class="real-pic"
      />
    </div>
  </div>
  <div class="cards user-card me-3">
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Business ID</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="User Name"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ businessIdData?.id }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Business Name</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="Business Name"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ businessIdData?.name }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">First Name</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="First Name"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ businessIdData?.user?.first_name }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Last Name</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="Last Name"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ businessIdData?.user?.last_name }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Email</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="Email"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ businessIdData?.user?.email }}"
        disabled
      />
    </div>

    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Address</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="Address"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ businessIdData?.address }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Account Holder name</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="Bank name"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ businessPaymentIdData?.account_holder_name }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Account Number</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="account number"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ businessPaymentIdData?.account_number }}"
        disabled
      />
    </div>
    <div class="row mb-4 p-3">
      <h3 class="cards-heading">License</h3>
      <div class="h-10 w-10">
        <img
          [src]="businessIdData?.user?.user_lisence?.doc_pic_front ? assetURL + businessIdData.user.user_lisence.doc_pic_front : '../../../assets/img/license.png'"
          alt="Liscence certificate front pic"
          class="real-pic"
          (click)="
            showEnlargedView(businessIdData?.user?.user_lisence?.doc_pic_front)
          "
        />
      </div>
      <div class="h-10 w-10">
        <img
          [src]="businessIdData?.user?.user_lisence?.doc_pic_back ? assetURL + businessIdData.user.user_lisence.doc_pic_back : '../../../assets/img/license.png'"
          alt="Liscence certificate back pic"
          class="real-pic"
          (click)="
            showEnlargedView(businessIdData?.user?.user_lisence?.doc_pic_back)
          "
        />
      </div>
      <div
        class="enlarged-view"
        [ngStyle]="enlargedViewStyle"
        *ngIf="showEnlarged"
      >
        <span class="close-icon" (click)="closeEnlargedView()">&times;</span>
        <img
          class="enlargeimg"
          [src]="enlargedImageUrl ? assetURL + enlargedImageUrl : '../../../assets/img/license.png'"
          alt="Enlarged Image"
        />
      </div>
    </div>
    <div class="row mb-4 p-3">
      <h3 class="cards-heading">Liquor License and Business Certificate</h3>
      <div class="h-10 w-10">
        <img
          [src]="businessIdData?.liq_cert ? assetURL + businessIdData.liq_cert : '../../../assets/img/license.png'"
          alt="liquor certificate"
          class="real-pic"
          (click)="showEnlargedView(businessIdData?.liq_cert)"
        />
      </div>
      <div class="h-10 w-10">
        <img
          [src]="businessIdData?.bus_cert ? assetURL + businessIdData.bus_cert : '../../../assets/img/license.png'"
          alt="business certificate"
          class="real-pic"
          (click)="showEnlargedView(businessIdData?.bus_cert)"
        />
      </div>
      <div
        class="enlarged-view"
        [ngStyle]="enlargedViewStyle"
        *ngIf="showEnlarged"
      >
        <span class="close-icon" (click)="closeEnlargedView()">&times;</span>
        <img
          class="enlargeimg"
          [src]="enlargedImageUrl ? assetURL + enlargedImageUrl : '../../../assets/img/license.png'"
          alt="Enlarged Image"
        />
      </div>
    </div>
  </div>
</div>
