<div class="main-content main-content-login">
  <div class="d-flex">
    <div class="login-lt-wrapper">
      <div class="logo-wrapper">
        <img src="../../../assets/img/login-logo.svg" alt="logo" class="logo" />
      </div>
      <p class="logo-text">Drink : The Managing Portal</p>
    </div>
    <div class="login-rt-wrapper">
      <div class="form-wrapper">
        <h2 class="form-heading">Login to your Account</h2>
        <p class="sub-heading">Login using your details</p>
        <div class="mt-3">
          <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="mb-3">
              <label for="emailInput" class="form-label input-label"
                >Email</label
              >
              <input
                type="text"
                class="form-control input-area"
                formControlName="email"
                id="emailInput"
                placeholder="Please enter your email address"
              />
              <div
                *ngIf="isSubmitted && formControls.email.errors"
                class="form-error-block"
              >
                <div
                  *ngIf="formControls.email.errors.required"
                  class="error-msg"
                >
                  Email is required
                </div>
                <div *ngIf="formControls.email.errors.email" class="error-msg">
                  Email is invalid!
                </div>
              </div>
            </div>
            <div class="mb-2">
              <label for="passwordInput" class="form-label input-label"
                >Password</label
              >
              <input
                type="password"
                class="form-control input-area"
                id="passwordInput"
                formControlName="password"
                placeholder="please enter your password"
              />
              <div
                *ngIf="isSubmitted && formControls.password.errors"
                class="form-error"
              >
                <div
                  *ngIf="formControls.password.errors.required"
                  class="error-msg"
                >
                  Password is required
                </div>
                <div *ngIf="errorMessage" class="error-msg">
                  {{ errorMessage }}
                </div>
                <!-- <div *ngIf="incorrectPassword" class="error-msg">
                  Wrong password. Please try again.
                </div> -->
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="form-check ms-4">
                <!-- <input
                  class="form-check-input input-check"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                /> -->
                <!-- <label
                  class="form-check-label remember-text"
                  for="flexCheckDefault"
                >
                  Remember Me
                </label> -->
              </div>
              <a href="/enter-email" class="forgot-password"
                >Forgot Password?</a
              >
            </div>

            <div class="form-check ps-0" *ngIf="errorMessage !== ''">
              <label class="form-check-label error-msg" for="flexCheckDefault">
                {{ errorMessage }}
              </label>
            </div>
            <!-- <div class="form-check ms-4" *ngIf="errorMessage !==''">
                            <label class="form-check-label remember-text" for="flexCheckDefault">
                                {{errorMessage}}
                            </label>
                        </div> -->
            <button type="submit" class="btn btn-primary login-btn">
              Login
            </button>
            <!-- <p class="register-text text-center">Not Registered Yet? <a href="javascript:void()" class="create">Create an account</a></p>
                            <p class="other-link">
                                To check <a routerLink="/terms-condition" class="link-color"> Terms & condition </a> and
                                <a routerLink="/privacy-policy" class="link-color">Privacy Policy</a> 
                            </p> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
