import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-custom-toast",
  templateUrl: "./custom-toast.component.html",
  styleUrls: ["./custom-toast.component.css"],
})
// export class CustomToastComponent implements OnInit {
//   constructor() { }

//   ngOnInit(): void {
//   }

// }
export class CustomToastComponent {
  @Input() message: string = "";
  @Input() showToast: boolean = false;
}
