import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageServiceService } from "../page-service.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-create-new",
  templateUrl: "./create-new.component.html",
  styleUrls: ["./create-new.component.css"],
  providers:[DatePipe]
})
export class CreateNewComponent implements OnInit {
  //@ts-ignore
  generalForm: FormGroup;
  isSubmitted: any = false;
  selectedItem: string = "3";
  role: number | undefined;
  errorMessage: string = "";
  editPromo:boolean=false;
  editPromoDetails:any;
  showEditedForm:boolean=true;
  isToggled = true; 
  constructor(
    public pageService: PageServiceService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private datePipe: DatePipe,
    private router : Router
  ) {}

  ngOnInit(): void {
    this.generalForm = this.formBuilder.group({
      promoTitle: ["", Validators.required],
      promoCode: ["", Validators.required],
      discount: ["", Validators.required],
      from_date: [this.getTodaysDate(), Validators.required],
      to_date: [this.getTodaysDate(), Validators.required],
      status: [false],
    });
    this.route.queryParams.subscribe((params) => {
      if (params.title) {
        this.generalForm.get("promoTitle")?.setValue(params.title);
      }
      if(params.edit){
        this.editPromo = params.edit;
        this.setEditDetails()
      }
    });
    
  }

  setEditDetails(){
    this.pageService.getPromoDetails().subscribe((response) => {
      console.log('response---',response);
      console.log('this.generalForm---',this.generalForm);
      this.editPromoDetails= response;
      this.generalForm.get('promoTitle')?.setValue(this.editPromoDetails.title);
      this.generalForm.get('promoCode')?.setValue(this.editPromoDetails.promo_code);
      this.generalForm.get('discount')?.setValue(this.editPromoDetails.discount);
      this.generalForm.get('from_date')?.setValue(this.datePipe.transform(this.editPromoDetails.start_date,'yyyy-MM-dd'));
      this.generalForm.get('to_date')?.setValue(this.datePipe.transform(this.editPromoDetails.end_date,'yyyy-MM-dd'));
      this.selectedItem = this.editPromoDetails.role;
      this.isToggled = this.editPromoDetails.status;
      this.cd.detectChanges();
    },err =>{
      console.log('no data');setTimeout(() => {
        if(!this.editPromoDetails){
          this.editPromo = false;
          this.router.navigate(["/create-new"]);
        }
      }, 400);
      
    });
    
    
  }
  get f() {
    return this.generalForm.controls;
  }

  getTodaysDate() {
    let d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  setSelectedItem(option: string) {
    this.selectedItem = option;
  }
  createPromo() {
    if(this.editPromo && this.editPromoDetails){
      this.isSubmitted = true;
      if (this.generalForm.invalid) {
        return;
      }
      if (this.f.discount.value > 100) {
        return;
      }
      this.pageService.loading.emit(true);
      const body = {
        id: this.editPromoDetails.id,
        title: this.f.promoTitle.value,
        promo_code: this.f.promoCode.value,
        discount: parseFloat(this.f.discount.value),
        start_date: this.f.from_date.value,
        end_date: this.f.to_date.value,
        role: +this.selectedItem,
        status: this.isToggled,
      };
  
      this.pageService.updatePromo(body).subscribe(
        (res: any) => {
          if (res) {
            this.isSubmitted = false;
            this.editPromoDetails = null;
            this.editPromo = false;
            this.generalForm.reset();
            this.f.from_date.setValue(this.getTodaysDate());
            this.f.to_date.setValue(this.getTodaysDate());
            window.location.replace("/promotion");
            this.pageService.loading.emit(false);
          }
        },
        (error) => {
          this.pageService.loading.emit(false);
          this.errorMessage = error.error.message;
        }
      );
    }else{
      this.isSubmitted = true;
      if (this.generalForm.invalid) {
        return;
      }
      if (this.f.discount.value > 100) {
        return;
      }
      this.pageService.loading.emit(true);
      const body = {
        title: this.f.promoTitle.value,
        promo_code: this.f.promoCode.value,
        discount: this.f.discount.value,
        start_date: this.f.from_date.value,
        end_date: this.f.to_date.value,
        role: +this.selectedItem,
        status: this.isToggled,
      };
  
      this.pageService.createPromos(body).subscribe(
        (res: any) => {
          if (res) {
            this.isSubmitted = false;
            this.generalForm.reset();
            this.f.from_date.setValue(this.getTodaysDate());
            this.f.to_date.setValue(this.getTodaysDate());
            window.location.replace("/promotion");
            this.pageService.loading.emit(false);
          }
        },
        (error) => {
          this.pageService.loading.emit(false);
          this.errorMessage = error.error.message;
        }
      );
    }
    
  }
  onChangeStatus() {
    console.log('Toggle state:', this.isToggled);
   
  }
}
