import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageServiceService } from "../page-service.service";
@Component({
  selector: "app-drink-setting",
  templateUrl: "./drink-setting.component.html",
  styleUrls: ["./drink-setting.component.css"],
})
export class DrinkSettingComponent implements OnInit {
  //@ts-ignore
  addonForm: FormGroup;
  categoriesForm!: FormGroup;
  subcategoriesForm!: FormGroup;
  isSubmitted: any = false;
  addonsList: any = [];
  categoriesList: any = [];
  subCategoriesList: any = [];
  selectedItem: string = "Select Category";

  constructor(
    public pageService: PageServiceService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getCategories();
    this.getSubCategories();
    this.addonForm = this.formBuilder.group({
      addAddons: ["", Validators.required],
    });
    this.categoriesForm = this.formBuilder.group({
      categories: ["", Validators.required],
    });
    this.subcategoriesForm = this.formBuilder.group({
      subcategories: ["", Validators.required],
    });
  }
  setSelectedItem(event: any) {
    const clickedElementId = event.target.value;

    this.selectedItem = clickedElementId;
  }
  get f() {
    return this.addonForm.controls;
  }
  get catf() {
    return this.categoriesForm?.controls;
  }
  get subcatf() {
    return this.subcategoriesForm?.controls;
  }

  addCategories() {
    this.isSubmitted = true;
    if (!this.categoriesForm || this.categoriesForm.invalid) {
      return;
    }
    const body = {
      name: this.catf?.categories.value,
      userId: 0,
      busId: 0,
      barId: 0,
    };
    this.pageService.addCategories(body).subscribe((res: any) => {
      if (res) {
        window.location.reload();
        this.isSubmitted = false;
        this.addonForm.reset();
      }
    });
  }
  getCategories() {
    this.pageService.getCategories().subscribe((res: any) => {
      if (res) {
        this.categoriesList = res;
      }
    });
  }
  deleteCategories(event: any) {
    const clickedElementId = event.target.getAttribute("id");
    this.pageService
      .deleteCategories(clickedElementId)
      .subscribe((res: any) => {
        if (res) {
        }
        window.location.reload();
        this.pageService.loading.emit(false);
      });
  }
  addSubCategories() {
    this.isSubmitted = true;
    if (!this.subcategoriesForm || this.subcategoriesForm.invalid) {
      return;
    }
    const body = {
      name: this.subcatf?.subcategories.value,
      categoryName: this.selectedItem,
      categoryId: 1,
    };
    this.pageService.addSubCategories(body).subscribe((res: any) => {
      if (res) {
        window.location.reload();
        this.isSubmitted = false;
        this.addonForm.reset();
      }
    });
  }
  getSubCategories() {
    this.pageService.getSubCategories().subscribe((res: any) => {
      if (res) {
        this.subCategoriesList = res;
      }
    });
  }
  deleteSubCategories(event: any) {
    const clickedElementId = event.target.getAttribute("id");
    this.pageService
      .deleteSubCategories(clickedElementId)
      .subscribe((res: any) => {
        if (res) {
        }
        window.location.reload();
        this.pageService.loading.emit(false);
      });
  }
}
