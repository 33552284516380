import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { PageServiceService } from "../page-service.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-edit-editors",
  templateUrl: "./edit-editors.component.html",
  styleUrls: ["./edit-editors.component.css"],
})
export class EditEditorsComponent implements OnInit {
  //@ts-ignore
  generalForm: FormGroup;
  isSubmitted: any = false;
  baseUrl: string = environment.baseUrl;
  assetURL: string = environment.assetURL;
  editorList: any = [];
  editorId: any;
  editorData: any;
  editorIdImg: any;
  img: any;
  long: any;
  lat: any;

  constructor(
    public pageService: PageServiceService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.long = position.coords.longitude;
        this.lat = position.coords.latitude;
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
    this.getEditors();
    setTimeout(() => {
      this.fetchdata();
    }, 1000);
    this.generalForm = this.formBuilder.group({
      fname: ["", Validators.required],
      lname: ["", Validators.required],
      username: ["", Validators.required],
      email: ["", Validators.required],
      address: [""],
      contrycode: [""],
      country: [""],
      city: [""],
      state: [""],
      zipcode: [""],
      mobilenumber: [""],
    });
  }
  fetchdata() {
    this.editorId = this.activatedRoute.snapshot.paramMap.get("id");
    this.editorData = this.editorList.find(
      (x: { id: any }) => x.id == this.editorId
    );
    this.generalForm.get("fname")?.setValue(this.editorData?.first_name);
    this.generalForm.get("lname")?.setValue(this.editorData?.last_name);
    this.generalForm.get("username")?.setValue(this.editorData?.user_name);
    this.generalForm.get("email")?.setValue(this.editorData?.email);
    this.generalForm.get("address")?.setValue(this.editorData?.address1);
    this.generalForm.get("contrycode")?.setValue(this.editorData?.country_code);
    this.generalForm.get("country")?.setValue(this.editorData?.country);
    this.generalForm.get("city")?.setValue(this.editorData?.city);
    this.generalForm.get("state")?.setValue(this.editorData?.state);
    this.generalForm.get("zipcode")?.setValue(this.editorData?.zipcode);
    this.generalForm.get("mobilenumber")?.setValue(this.editorData?.mobile_no);
    this.editorIdImg = this.editorList.find(
      (x: { id: any }) => x.id == this.editorId
    );
  }
  get f() {
    return this.generalForm.controls;
  }
  getEditors() {
    this.pageService.getEditor().subscribe((res: any) => {
      if (res) {
        this.editorList = res;
      }
      this.pageService.loading.emit(false);
    });
  }
  updateEditor() {
    this.isSubmitted = true;
    if (this.generalForm.invalid) {
      return;
    }
    this.pageService.loading.emit(true);
    const event = new Date("05 October 2023 14:48 UTC");
    const body = {
      id: +this.editorData.id,
      user_name: this.f.username.value,
      first_name: this.f.fname.value,
      last_name: this.f.lname.value,
      email: this.f.email.value,
      address1: this.f.address.value ? this.f.address.value : "",
      country_code: this.f.contrycode.value
        ? this.f.contrycode.value.toString()
        : "",
      mobile_no: this.f.mobilenumber.value
        ? this.f.mobilenumber.value.toString()
        : "",
      country: this.f.country.value ? this.f.country.value : "",
      city: this.f.city.value ? this.f.city.value : "",
      state: this.f.state.value ? this.f.state.value : "",
      zipcode: +this.f.zipcode.value ? this.f.zipcode.value : "",
      doc_pic_front: this.editorData.doc_pic_front
        ? this.editorData.doc_pic_front
        : "null",
      doc_pic_back: this.editorData.doc_pic_back
        ? this.editorData.doc_pic_back
        : "null",
      profile_url: this.img ? this.img : "",
      doc_expires_on: event.toISOString(),
      latitude: this.lat.toString(),
      longitude: this.long.toString(),
      step: 0,
    };
    this.pageService.updateEditor(body).subscribe((res: any) => {
      if (res) {
        this.isSubmitted = false;
        this.generalForm.reset();
        window.location.replace("/dashboard");
        this.pageService.loading.emit(false);
      }
    });
  }
  uploadImage(event: any) {
    const formData = new FormData();
    formData.append("productImage", event.target.files[0]);
    const url = "https://api-dev.drinkapp.us/uploads";
    this.http.post(url, formData).subscribe((res: any) => {
      if (res) {
        this.img = res.data[0].path;
      }
      this.pageService.loading.emit(false);
    });
  }
}
