<!-- .........content......... -->
<div class="content">
  <div class="row mb-4">
    <div class="cards invite-card">
      <div
        class="cards-header d-flex justify-content-between align-items-center"
      >
        <h3 class="cards-heading">Guest Help Requests</h3>
      </div>
      <ng-container *ngFor="let query of queriesList">
        <div *ngIf="query.user?.role === 3">
          <div>
            <div class="d-flex align-items-center time-wrapper">
              <div class="d-flex align-items-center">
                <p class="date">
                  {{ query.doc | date : "MM/dd/yyyy : hh:mm a" }}
                </p>
              </div>

              <div
                class="mobile-show align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center">
                  <div class="mx-5 w-10 text-center">
                    <img *ngIf="query.is_starred" class="favorite-star" (click)="selectFav(query.id,false)" src="../../../assets/img/star-fill.svg" alt="" />
                    <img *ngIf="!query.is_starred" class="favorite-star" (click)="selectFav(query.id,true)" src="../../../assets/img/star-empty.svg" alt="" />
                  </div>
                  <p class="dot ms-2"></p>
                  <div
                    class="status {{
                      query?.status ? 'status-open' : 'status-close'
                    }} mx-5"
                  >
                    {{ query?.status ? "Open" : "Closed" }}
                  </div>
                </div>
                <div class="wid-tg-15">
                  <div class="tag guest-tag mx-5">
                    {{ query?.user?.role === 3 ? "Guest" : "Business" }}
                  </div>
                </div>
              </div>
            </div>
            <div class="guest-row">
              <div class="d-flex" style="width: 511px">
                <div class="profle-pic me-3">
                  <ng-container
                    *ngIf="
                      query.user?.profile_url && query.user?.profile_url !== '';
                      else elseTemp1
                    "
                  >
                    <img
                      src="{{ assetURL }}{{ query.user?.profile_url }}"
                      alt="managers"
                      class="real-pic"
                    />
                  </ng-container>
                  <ng-template #elseTemp1>
                    <img
                      src="../../../assets/img/user.png"
                      alt="managers"
                    />
                  </ng-template>
                </div>
                <div class="profile-details">
                  <div>
                    <p>Ticket no: {{ query?.id }}</p>
                  </div>
                  <h1 class="profile-name my-1">
                    {{ query?.first_name }} {{ query?.last_name }}
                  </h1>
                  <p class="profile-message">{{ query?.subject }}</p>
                </div>
              </div>

              <div class="wid-tg-15 mobile-hide">
                <div
                  class="tag {{
                    query?.user?.role === 3 ? 'guest-tag' : 'business-tag'
                  }} mx-5"
                >
                  {{ query?.user?.role === 3 ? "Guest" : "Business" }}
                </div>
              </div>
              <div
                class="status {{
                  query?.status ? 'status-open' : 'status-close'
                }} mx-5 mobile-hide"
              >
                {{ query?.status ? "Open" : "Closed" }}
              </div>
              <div class="mx-5 w-10 text-center mobile-hide">
                <img *ngIf="query.is_starred" class="favorite-star" (click)="selectFav(query.id,false)" src="../../../assets/img/star-fill.svg" alt="" />
                <img *ngIf="!query.is_starred" class="favorite-star" (click)="selectFav(query.id,true)" src="../../../assets/img/star-empty.svg" alt="" />
              </div>
              <div routerLink="/help-center/ticket/{{ query?.id }}">
                <button type="button" class="btn invite-btn ms-2">View</button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row mb-4">
    <div class="cards invite-card">
      <div
        class="cards-header d-flex justify-content-between align-items-center"
      >
        <h3 class="cards-heading">Business Help Requests</h3>
      </div>
      <ng-container *ngFor="let query of queriesList">
        <div *ngIf="query.user?.role === 2">
          <div>
            <div class="d-flex align-items-center time-wrapper">
              <div class="d-flex align-items-center">
                <p class="date">
                  {{ query.doc | date : "MM/dd/yyyy : hh:mm a" }}
                </p>
              </div>

              <div
                class="mobile-show align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center">
                  <div class="mx-5 w-10 text-center">
                    <img *ngIf="query.is_starred" class="favorite-star" (click)="selectFav(query.id,false)" src="../../../assets/img/star-fill.svg" alt="" />
                    <img *ngIf="!query.is_starred" class="favorite-star" (click)="selectFav(query.id,true)" src="../../../assets/img/star-empty.svg" alt="" />
                  </div>
                  <p class="dot ms-2"></p>
                  <div
                    class="status {{
                      query?.status ? 'status-open' : 'status-close'
                    }} mx-5"
                  >
                    {{ query?.status ? "Open" : "Closed" }}
                  </div>
                </div>
                <div class="wid-tg-15">
                  <div class="tag guest-tag mx-5">
                    {{ query?.user?.role === 2 ? "Business" : "Guest" }}
                  </div>
                </div>
              </div>
            </div>
            <div class="guest-row">
              <div class="d-flex" style="width: 511px">
                <div class="profle-pic me-3">
                  <ng-container
                    *ngIf="
                      query.user?.profile_url && query.user?.profile_url !== '';
                      else elseTemp1
                    "
                  >
                    <img
                      src="{{ assetURL }}{{ query.user?.profile_url }}"
                      alt="managers"
                      class="real-pic"
                    />
                  </ng-container>
                  <ng-template #elseTemp1>
                    <img
                      src="../../../assets/img/user.png"
                      alt="managers"
                    />
                  </ng-template>
                </div>
                <div class="profile-details">
                  <div>
                    <p>Ticket no: {{ query?.id }}</p>
                  </div>
                  <h1 class="profile-name my-1">
                    {{ query?.first_name }} {{ query?.last_name }}
                  </h1>
                  <p class="profile-message">{{ query?.message }}</p>
                </div>
              </div>

              <div class="wid-tg-15 mobile-hide">
                <div
                  class="tag {{
                    query?.user?.role === 2 ? 'business-tag' : 'guest-tag'
                  }} mx-5"
                >
                  {{ query?.user?.role === 2 ? "Business" : "Guest" }}
                </div>
              </div>
              <div
                class="status {{
                  query?.status ? 'status-open' : 'status-close'
                }} mx-5 mobile-hide"
              >
                {{ query?.status ? "Open" : "Closed" }}
              </div>
              <div class="mx-5 w-10 text-center mobile-hide">
                <img *ngIf="query.is_starred" class="favorite-star" (click)="selectFav(query.id,false)" src="../../../assets/img/star-fill.svg" alt="" />
                <img *ngIf="!query.is_starred" class="favorite-star" (click)="selectFav(query.id,true)" src="../../../assets/img/star-empty.svg" alt="" />
              </div>
              <div routerLink="/help-center/ticket/{{ query?.id }}">
                <button type="button" class="btn invite-btn ms-2">View</button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
