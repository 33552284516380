<div class="content">
  <ng-container>
    <div class="d-flex align-items-center pb-4">
      <a routerLink="/payment">
        <img src="../../../assets/img/left.png" alt="back" width="28px" />
      </a>

      <h3 class="cards-heading mx-3">View Payment Details</h3>
    </div>
    <div class="form-row p-4">
      <div class="form-group col-md-6">
        <h4>Name</h4>
        <input
          type="text"
          class="form-control input-area"
          id="inputEmail4"
          placeholder="Name"
          disabled
          value="{{ paymentIdData?.user?.first_name }} {{
            paymentIdData?.user?.last_name
          }} "
        />
      </div>
    </div>
    <div class="form-row p-4">
      <div class="form-group col-md-6">
        <h4>Email</h4>
        <input
          type="email"
          class="form-control input-area"
          id="inputEmail4"
          placeholder="Email"
          disabled
          value="{{ paymentIdData?.user?.email }}"
        />
      </div>
    </div>

    <div class="form-row p-4">
      <div class="form-group col-md-6">
        <h4>Date & Time</h4>
        <input
          type="text"
          class="form-control input-area"
          id="inputAddress"
          disabled
          value="{{ paymentIdData?.doc | date : 'M/d/yy' }}"
        />
      </div>
    </div>

    <div class="form-row p-4">
      <div class="form-group col-md-6">
        <h4>Tips</h4>
        <input
          type="text"
          class="form-control input-area"
          id="inputAddress"
          placeholder="tips"
          disabled
          value="${{ paymentIdData?.order?.tip }}"
        />
      </div>
    </div>
    <div class="form-row p-4">
      <div class="form-group col-md-6">
        <h4>Tax</h4>
        <input
          type="text"
          class="form-control input-area"
          id="inputAddress"
          placeholder="tax"
          disabled
          value="${{ paymentIdData?.order?.tax }}"
        />
      </div>
    </div>

    <div class="form-row p-4">
      <div class="form-group col-md-6">
        <h4>Service Fee</h4>
        <input
          type="text"
          class="form-control input-area"
          id="inputAddress"
          placeholder="tax"
          disabled
          value="${{ paymentIdData?.order?.service }}"
        />
      </div>
    </div>
    <div class="form-row p-4">
      <div class="form-group col-md-6">
        <h4>Total Payment</h4>
        <input
          type="text"
          class="form-control input-area"
          id="inputAddress"
          placeholder="$"
          disabled
          value="${{ paymentIdData?.amount }}"
        />
      </div>
    </div>

    <div
      class="form-row p-4"
      *ngIf="paymentIdData?.refunds && paymentIdData.refunds.length > 0"
    >
      <div class="form-group col-md-6">
        <h4>Refund Type</h4>
        <input
          type="text"
          class="form-control input-area"
          id="inputAddress"
          placeholder="$"
          disabled
          value="{{ paymentIdData?.refunds[0].metadata?.refund_type }}"
        />
      </div>
    </div>

    <div
      class="form-row p-4"
      *ngIf="paymentIdData?.refunds && paymentIdData.refunds.length > 0"
    >
      <div class="form-group col-md-6">
        <h4>Total Refunded Payment</h4>
        <input
          type="text"
          class="form-control input-area"
          id="inputAddress"
          placeholder="$"
          disabled
          value="${{ paymentIdData?.refunds[0]?.amount }}"
        />
      </div>
    </div>

    <div class="form-row p-4">
      <div class="form-group col-md-6">
        <h4>Ordered Drinks</h4>
        <div class="list-wrapper row">
          <ng-container
            *ngFor="let drink of paymentIdData?.order?.order_drinks"
          >
            <div class="wid-tg-auto p-1">
              <div class="tag guest-tag mx-5 my-1">
                Drink Name: {{ drink?.drink_size_options?.drinks?.name }}
              </div>
              <div class="tag guest-tag mx-5">
                Drink Price: {{ drink?.drink_size_options?.price }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>
