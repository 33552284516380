import { Component, OnInit, ViewChild } from "@angular/core";
import { PageServiceService } from "../page-service.service";
import { NgbModal, NgbModalRef,ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  businessList: any = [];
  userList: any = [];
  user: any;
  business: any;
  sidebarVisible: boolean = false;
  @ViewChild('sessionPopup') sessionPopup:NgbModal | undefined;
  private modalRef: NgbModalRef | undefined;
  isModalOpen :boolean=false;
  idleState:any='NOT STARTED';
  idleTime:any;
  timedOut:boolean=false;
  lastPing?: Date ;

  constructor(public pageService: PageServiceService,
    
    private idle: Idle, private keepalive: Keepalive,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    // this.getusers();
    // this.getBusiness();
    
    this.idleTimer(60);
  }

  logout() {
    this.pageService.logout();
  }
  openNav() {
    (document.getElementById("sideNavbar") as HTMLElement).style.display =
      "block";
  }
  closeNav() {
    (document.getElementById("sideNavbar") as HTMLElement).style.display =
      "none";
  }

  getusers() {
    this.pageService.getUnverifyUser().subscribe((res: any) => {
      if (res) {
        this.userList = res;
        this.user = this.userList.length;
      }
    });
  }
  getBusiness() {
    this.pageService.getUnverifyBusiness().subscribe((res: any) => {
      if (res) {
        this.businessList = res;
        this.business = this.businessList.length;
      }
      this.pageService.loading.emit(false);
    });
  }

  idleTimer(timer:number){
    // Set idle time (e.g., 5 minutes)
    this.idle.setIdle(1); // 300 seconds = 5 minutes

    // Set timeout duration (e.g., after 60 seconds of idle time)
    this.idle.setTimeout(timer); // 60 seconds = 1 minute

    // Watch for user activity (mouse, keyboard, touch, etc.)
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // When user becomes idle
    this.idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      // alert('You have been logged out due to inactivity.');
      if(!this.isModalOpen){
       
        if (!this.modalService.hasOpenModals()) {
          this.isModalOpen  = true;
          this.stopIdleWatch();
          this.modalRef =  this.modalService.open(this.sessionPopup, { centered: true,size: 'sm' });
          this.modalRef.result.finally(() => {
            console.log('closed');
            
            this.isModalOpen = false; // Reset when modal closes
          });
            // setTimeout(() => {
            //   if(this.isModalOpen){
            //       this.logoutSession();
            //   }
            // }, 60000);
          this.modalRef.result.then(
            (result) => {
              this.isModalOpen = false;
              console.log('Modal closed with:', result);
            },
            (reason) => {
              if (reason === ModalDismissReasons.ESC) {
                console.log('Modal closed with ESC key');
                this.closeModal('session_logout');
                this.isModalOpen = false;
                this.pageService.logout();
              } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
                console.log('Modal closed by clicking outside');
                this.closeModal('session_logout');
                this.isModalOpen = false;
                this.pageService.logout();
              } else {
                console.log('Modal dismissed with:', reason);
                this.isModalOpen = false;
              }
            }
          );
        }
        
      }else{
        this.pageService.logout();
        this.closeModal('session_logout');
      }
    
    });
    this.idle.onIdleStart.subscribe(() => this.idleState = 'You have gone idle.');
    this.idle.onTimeoutWarning.subscribe((countdown:any) => {
      this.idleState = `You will time out in ${countdown} seconds! ${this.isModalOpen}`;
      // console.log(countdown,this.isModalOpen);
      
    }
    
    );

    // Start keepalive service
    this.keepalive.interval(15); // Ping server every 15 seconds
    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    // Start watching for inactivity
    this.reset();
  }
  stopIdleWatch() {
    this.idle.stop(); // Stops the idle watch
    console.log('Idle watch stopped');
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started watching.';
    this.timedOut = false;
  }
 
  sessionContinue(){
    this.reset();
    this.closeModal('session_continue');
  }

  logoutSession(){

    this.pageService.logout();
    this.closeModal('session_logout');
  }
  closeModal(reason:any) {
    if (this.modalRef) {
      this.modalRef.close(reason); // Close the specific modal
    }
  }

  dismissModal() {
    this.modalService.dismissAll(); // Close all modals
  }
}
