<div class="main-content main-content-login">
  <div class="d-flex">
    <div class="login-lt-wrapper">
      <div class="logo-wrapper">
        <img src="../../../assets/img/login-logo.svg" alt="logo" class="logo" />
      </div>
      <p class="logo-text">The Drink App: Managing Portal</p>
    </div>
    <div class="login-rt-wrapper">
      <div class="form-wrapper">
        <h2 class="form-heading">Forgot Password</h2>
        <div class="mt-3">
          <form [formGroup]="forgotPasswordForm" (ngSubmit)="resetPassword()">
            <div class="mb-3">
              <label for="passwordInput" class="form-label input-label"
                >Enter OTP</label
              >
              <input
                type="text"
                class="form-control input-area"
                id="tokenumber"
                formControlName="token"
                placeholder="please enter OTP"
              />
              <!-- <div
                *ngIf="isSubmitted && formControls.token.errors"
                class="form-error-block"
              >
                <div *ngIf="formControls.token.required" class="error-msg">
                  OTP is required
                </div>
                <div *ngIf="formControls.token.errors.token" class="error-msg">
                  OTP is invalid!
                </div>
              </div> -->
            </div>
            <div class="mb-2">
              <label for="passwordInput" class="form-label input-label"
                >Enter new Password</label
              >
              <input
                type="password"
                class="form-control input-area"
                id="passwordInput"
                formControlName="password"
                placeholder="please enter new password"
              />
              <div
                *ngIf="isSubmitted && formControls.password.errors"
                class="form-error"
              >
                <div
                  *ngIf="formControls.password.errors.required"
                  class="error-msg"
                >
                  Password is required
                </div>
              </div>
            </div>
            <div class="mb-2">
              <label for="passwordInput" class="form-label input-label"
                >Enter Email</label
              >
              <input
                type="text"
                class="form-control input-area"
                id="emailInput"
                formControlName="email"
                placeholder="please enter your email"
              />
              <div
                *ngIf="isSubmitted && formControls.email.errors"
                class="form-error-block"
              >
                <div
                  *ngIf="formControls.email.errors.required"
                  class="error-msg"
                >
                  Email is required
                </div>
                <div *ngIf="formControls.email.errors.email" class="error-msg">
                  Email is invalid!
                </div>
              </div>
            </div>
            <p class="status-open">{{ errorMessage }}</p>

            <button type="submit" class="btn btn-primary login-btn">
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
