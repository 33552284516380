import { Component, OnInit } from "@angular/core";
import { PageServiceService } from "../page-service.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-help-request",
  templateUrl: "./help-request.component.html",
  styleUrls: ["./help-request.component.css"],
})
export class HelpRequestComponent implements OnInit {
  queriesList: any = [];
  baseUrl: string = environment.baseUrl;
  assetURL: string = environment.assetURL;
  constructor(public pageService: PageServiceService) {}

  ngOnInit(): void {
    this.getqueryList();
  }
  getqueryList() {
    this.pageService.loading.emit(true);
    this.pageService.getqueryList().subscribe((res: any) => {
      if (res.success) {
        this.queriesList = res.data;
        // this.queriesList.sort((a:any,b:any) => {})
        this.queriesList.sort((a:any, b:any) => new Date(b.is_starred).getTime() - new Date(a.is_starred).getTime());

      }
      this.pageService.loading.emit(false);
    });
  }

  selectFav(queryId:any,star:boolean){
    this.pageService.loading.emit(true);
    let body = {
      star:star
    }
    this.pageService.getqueryFavList(queryId,body).subscribe((res: any) => {
      if (res.success) {
        this.queriesList = [];
        this.getqueryList();
        // this.queriesList = res.data;
      }
      this.pageService.loading.emit(false);
    });
  }
}
